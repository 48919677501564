import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getTasks } from '../../services/api/tasks'

const initialState = {
    tasks: [

    ]
}

export const fetchTasks = createAsyncThunk(
    'tasks/get',
    async () => {
        // const response = await getTasks()
        // return response
        return await getTasks()

    }
)


export const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTasks.fulfilled, (state, action) => {
            state.tasks = action.payload
        })
        builder.addCase(fetchTasks.rejected, (state) => {
            state.tasks=[]
        })
    }
})


export const allTasks = (state) => state.tasks.tasks




export default tasksSlice.reducer