import React from "react";
import styles from './../../styles/home.module.scss'

const BigOrderItem = ({bigOrderList, showPersonalBlock}) => {

    return (
        <li className={`${styles.big_order_item} `}
        onClick={showPersonalBlock}
        >
            {/*<div className={styles.big_order_pos}>*/}
            <div className={styles.menu_title}>{bigOrderList.title} </div>
            <div className={styles.menu_date}>{bigOrderList.date}</div>
            {/*</div>*/}
        </li>
    )

}

export default BigOrderItem