import styles from "../../../styles/home.module.scss";
import Search from "../../../components/Search/Search";
import bell from "../../../assets/icons/bell.svg";
import globus from "../../../assets/icons/globus.svg";
import OrderList from "../../../components/OrderList/OrderList";
import sms from "../../../assets/icons/sms.svg";
import mail from "../../../assets/icons/mail.svg";
import plus_blue from "../../../assets/icons/plus_blue.svg";
import React from "react";
import { useSelector } from "react-redux";
import { accountBalanceSelector } from "../../../store/slices/stats";
import {useEffect} from "react/react.shared-subset";
import {fetchTasks} from "../../../store/slices/tasks";
import {Layout} from "../../../components/Layout/Layout";

export function HomeHeader(): JSX.Element {
  const [isBigListOpen, setIsBigListOpen] = React.useState(false)
  const handleButtonClick = () => {
    setIsBigListOpen(!isBigListOpen);
  };

  const accountBalance = useSelector(accountBalanceSelector);
  
  const [isOrdListOpen, setIsOrdListOpen] = React.useState(false)
  const order_list_item = `${isOrdListOpen ? styles.show_order : ''}`

  const orderSubList = [
    {
      title: 'Заказ №2505-103-0',
      state: 'Новый',
      date: 'Создан 24 мая 2023г. в 11:39',
      cleaningDate: '25 мая 2023г.',
      cleaningTime: '09:00',
      frequency: '1',
      customer: 'Лидия',
      phone: '+7 (962) 944-23-74',
      address: 'Мусы Джалиля, 13',
      paymentType: 'Наличные',
      nameType: 'Стандартная уборка',
      namePlace: '1 комната; Коридор; Кухня; Ванна и туалет',
      cost: '1 890,00р.',
      discount: '0,00р.',
      total: '1 890,00р.',
    },
    {
      title: 'Заказ №2505-103-1',
      state: 'Новый',
      date: 'Создан 24 мая 2023г. в 11:39',
      cleaningDate: '25 мая 2023г.',
      cleaningTime: '09:00',
      frequency: '1',
      customer: 'Лидия',
      phone: '+7 (962) 944-23-74',
      address: 'Мусы Джалиля, 13',
      paymentType: 'Наличные',
      nameType: 'Стандартная уборка',
      namePlace: '1 комната; Коридор; Кухня; Ванна и туалет',
      cost: '1 890,00р.',
      discount: '0,00р.',
      total: '1 890,00р.',
    },
    {
      title: 'Заказ №2505-103-2',
      state: 'Новый',
      date: 'Создан 24 мая 2023г. в 11:39',
      cleaningDate: '25 мая 2023г.',
      cleaningTime: '09:00',
      frequency: '1',
      customer: 'Лидия',
      phone: '+7 (962) 944-23-74',
      address: 'Мусы Джалиля, 13',
      paymentType: 'Наличные',
      nameType: 'Стандартная уборка',
      namePlace: '1 комната; Коридор; Кухня; Ванна и туалет',
      cost: '1 890,00р.',
      discount: '0,00р.',
      total: '1 890,00р.',
    },
  ]

  return (

        <div className={styles.header}>
          <div className={styles.header_input}>
            <Search handleButtonClick={handleButtonClick}/>
          </div>
          <div className={styles.actions}>
            <button className={styles.callBack_btn}>
              <span className={styles.callBack_title}>Обратный звонок </span>
              <img className={styles.callBack_icon} src={bell.toString()}/>
            </button>
          </div>
          <div className={`${styles.actions} ${order_list_item}`}>
            <button
                className={styles.onlineOrder_btn}
                onClick={() => setIsOrdListOpen(!isOrdListOpen)}
            >
              <span className={styles.onlineOrder_title}>Онлайн заказ</span>
              <img className={styles.onlineOrder_icon} src={globus.toString()}/>
            </button>
            <OrderList
                isOrdListOpen={isOrdListOpen}
                orderSubList={orderSubList}
                setIsOrdListOpen={setIsOrdListOpen}
            />
          </div>
          <div className={styles.social}>
            <button className={styles.sms_btn}>
              <img src={sms.toString()}/>
            </button>
          </div>
          <div className={styles.social}>
            <button className={styles.mail_btn}>
              <img src={mail.toString()}/>
            </button>
          </div>
          <div className={styles.balance_block}>
            <span className={styles.balance_amount}>{accountBalance} ₽</span>
            <span className={styles.balance_title}>Баланс</span>
            <button className={styles.balance_btn}></button>
          </div>
          <div className={styles.employee}>
            <button className={styles.employee_btn}>
              <img src={plus_blue.toString()}/> Создать заказ
            </button>
          </div>
        </div>


  )
}
