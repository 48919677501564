import React from 'react'
import SideBar from '../../components/sideBar/sideBar'
import styles from './mainWrapper.module.scss'
export default function MainWrapper({children}) {
    return (
        <div className={styles.wrapper}>
            <SideBar  />
            {children}
        </div>
    )
}
