import React from "react";
import styles from '../../styles/home.module.scss'
import {ReactComponent as GlobusBlue} from "../../assets/icons/globus_blue.svg";
import {ReactComponent as CloseBtn} from "../../assets/icons/close.svg";

const OrderItem = ({orderSubList}) => {
    const [isOrderInfo, setIsOrderInfo] = React.useState(false)
    return (
        <li className={styles.order_item} onClick={() => setIsOrderInfo(!isOrderInfo)}>
            <div className={styles.order_item_pos}>
                <div className={styles.order_item_ttl}>{orderSubList.title}</div>
                <div className={styles.order_item_st}>{orderSubList.state}</div>
            </div>
            <span className={styles.order_item_dt}>{orderSubList.date}</span>

            <div className={styles.order_inf} style={{display: isOrderInfo ? 'block' : 'none'}}>
                <div className={styles.order_list_pos}>
                    <div className={styles.order_list_ttl}>
                        <GlobusBlue/> Информация о заказе
                    </div>
                    <div className={styles.order_close_btn} onClick={() => setIsOrderInfo(false)}>
                        <CloseBtn/>
                    </div>
                </div>
                <div className={styles.order_item_pos}>
                    <div className={styles.order_item_ttl}>{orderSubList.title}</div>
                    <div className={styles.order_item_st}>{orderSubList.state}</div>
                </div>
                <span className={styles.order_item_dt}>{orderSubList.date}</span>
                <ul className={styles.order_list_info}>
                    <li><span>Дата Уборки:</span> <span>{orderSubList.cleaningDate}</span></li>
                    <li><span>Время уборки:</span> <span>{orderSubList.cleaningTime} </span></li>
                    <li><span>Периодичность:</span> <span>{orderSubList.frequency}</span></li>
                    <li><span>Заказчик:</span><span>{orderSubList.customer}</span></li>
                    <li><span>Телефон:</span><span>{orderSubList.phone}</span></li>
                    <li><span>Адрес:</span><span>{orderSubList.address}</span></li>
                    <li><span>Тип оплаты:</span><span>{orderSubList.paymentType}</span></li>
                    <li>
                        {/*<span>Наименование</span>*/}
                        <div className={styles.order_pos_cost}>
                            <div>
                                <div>
                                    <div>Наименование</div>
                                </div>
                                <div>
                                    <span>{orderSubList.nameType}</span>
                                </div>
                                <div>
                                    <span>{orderSubList.namePlace}</span>

                                </div>
                            </div>

                            <div>
                                <div>
                                    <div>Стоимость</div>
                                </div>
                                <div>
                                    <span> {orderSubList.cost}</span>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className={styles.order_pos_total_block}>
                            <div>
                                <div>Скидка</div>
                                <div className={styles.order_total}>ИТОГО:</div>
                            </div>
                            <div>
                                <div className={styles.order_disc_cost}>{orderSubList.discount}</div>
                                <div className={styles.order_total_cost}>{orderSubList.total}</div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <button className={styles.order_submit}>Оформить заказ</button>
                        <button className={styles.order_close} onClick={() => setIsOrderInfo(false)}>Закрыть</button>
                    </li>
                </ul>

            </div>
        </li>
    )
}
export default OrderItem