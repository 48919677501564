import React from 'react'
import { useLogin } from '../hooks'
import logo from './../assets/icons/logo.svg'
import google from './../assets/icons/google.svg'
import styles from './../styles/LoginPage.module.scss'
import './../styles/index.scss'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { profileSelector } from '../store/slices/profile'
import { Link } from 'react-router-dom'
import { Redirect } from '../router/Redirect'

const LoginPage = () => {
	const { register, handleSubmit } = useForm()
	const profile = useSelector(profileSelector)

	const { mutate, error } = useLogin()
	
	if (profile.email) {
		return <Redirect to='/' />
	}
	
	const onSubmit = async (user) => {
		mutate({
			password: user.password,
			email: user.username
		})
	}
	
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className={styles.wrapper}>
					<img src={logo} className={styles.logo} alt='logo' />
					<div className={styles.container}>
						<h1 className={styles.title}>Логин</h1>
						<p className={styles.subTitle}>
							Еще нет аккаунта?{' '}
							<Link className={styles.link} to='register'>
								Создать аккаунт
							</Link>
						</p>
						<button className={styles.singIn_btn}>
							<img className={styles.google_logo} src={google} alt='google' />
							<p className={styles.title_btn}>Sign up with Google</p>
						</button>
						<span className={styles.line}>ИЛИ</span>
						<div className={styles.login}>
							<span className={styles.login_text}>Логин</span>
							<input placeholder='Login' className={styles.login_input} {...register('username', { required: true })} />
						</div>
						<div className={styles.password_wrapper}>
							<span className={styles.password_text}>Пароль</span>
						</div>
						<div className={styles.password_block}>
							<input placeholder='Password' className={styles.password_input}
							       type='password' {...register('password', { required: true })} />
							<div className={styles.password_items}>
								<input className={styles.password_checkbox} type='checkbox' />
								<span className={styles.password_stay}>Оставаться в системе</span>
							</div>
						</div>
						<div className={styles.error_message}>{error?.response?.data?.details}</div>
						<button className={styles.login_btn} type='submit'>Войти</button>
					</div>
				</div>
			</form>
		</>
	)
}

export default LoginPage
