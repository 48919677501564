import { Navigate } from 'react-router-dom'
import React from 'react'
import { useTypedSelector } from '../../hooks'

export function AuthGuard({ children }: { children: JSX.Element }): JSX.Element {
	const token = useTypedSelector(state => state.auth.token)
	const isNotAuth: boolean = token === undefined
	
	return isNotAuth ? <Navigate to='/login' replace /> : children
}
