import api from "..";
import { DEFAULT_ERROR_TEXT } from "../../../constants";

export async function getTasks() {
    try {
        const res = await api.get('planner/my-tasks');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}