import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getStats } from '../../services/api/stats'

const initialState = {
    account_balance: 0,
    totals:[
    ],
    stats:
    {
      leads: 0,
      today_sales: 0,
      clients_total: 0,
      orders_processed: 0
    }
}


export const fetchStats = createAsyncThunk(
    'stats/get',
    async () => {
      const response = await getStats()
      return response.data
    }
  )
  


export const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStats.fulfilled, (state, action) => {
     for (const key in action.payload) {
        state[key] = action.payload[key]
     }
    })
    builder.addCase(fetchStats.rejected, () => {
    })
  },
})


export const accountBalanceSelector = (state) => state.stats.account_balance
export const totalsSelector = (state) => state.stats.totals
export const statsSelector = (state) => state.stats.stats
export const totalsDateRangeSelector = (state)=> {
  return {
    start:state.stats.totals?.[0]?.date,
    end:state.stats.totals?.[state.stats.totals.length-1]?.date
  }
}