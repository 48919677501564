import React from 'react'
import logo from './../assets/icons/logo.svg'
import google from './../assets/icons/google.svg'
import styles from './../styles/SingUpPage.module.scss'
import './../styles/index.scss'
import { useForm } from 'react-hook-form'
import { signUp } from '../services/api/auth'
import { useSelector } from 'react-redux'
import { profileSelector } from '../store/slices/profile'
import { Link,  useNavigate } from 'react-router-dom'
import { Redirect } from "../router/Redirect";

export default function SignUpPage() {
    const { register, handleSubmit } = useForm();
    const profile = useSelector(profileSelector)
    const navigate = useNavigate()

    if (profile.email) {
        return <Redirect to='/' />
    }


    const onSubmit = async (user) => {
        const res = await signUp({ ...user, password_repeat: user.password });
        if (res.result === 'ok') {
            navigate("/login");
        }
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.wrapper}>
                    <img src={logo} className={styles.logo} alt='logo' />
                    <div className={styles.container}>
                        <h1 className={styles.title}>Регистрация
                        </h1>
                        <p className={styles.subTitle}>
                            Уже есть аккаунт?{' '}
                            <Link to='login' className={styles.link}>
                                Логин
                            </Link>

                        </p>
                        <button className={styles.singIn_btn}>
                            <img className={styles.google_logo} src={google} alt='google' />
                            <p className={styles.title_btn}>Sign up with Google</p>
                        </button>
                        <span className={styles.line}>ИЛИ</span>
                        <div className={styles.login}>
                            <span className={styles.login_text}>Email</span>
                            <input placeholder='Email' className={styles.login_input} {...register("email", { required: true })} />
                        </div>
                        <div className={styles.login}>
                            <span className={styles.login_text}>Логин</span>
                            <input placeholder='Login' className={styles.login_input} {...register("name", { required: true })} />
                        </div>
                        <div className={styles.password_wrapper}>
                            <span className={styles.password_text}>Пароль</span>
                        </div>
                        <div className={styles.password_block}>
                            <input placeholder='Password' className={styles.password_input} {...register("password", { required: true })} />
                        </div>
                        <button className={styles.login_btn} type='submit'>Регистрация</button>
                    </div>
                </div>
            </form>
        </>
    )
}
