import React, {useEffect} from 'react';
import styles from './../../styles/home.module.scss'
import {ReactComponent as BtnPrev} from "../../assets/icons/button_prev.svg";
import {ReactComponent as BtnNext} from "../../assets/icons/button_next.svg";
import BigOrderItem from './BigOrderItem'

const BigOrderList = ({bigOrderList, isBigListOpen}) => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = React.useState(1);
    const totalPages = Math.ceil(bigOrderList.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    }
    useEffect(() => {
        const buttons = document.querySelectorAll('.big_order_btn');

        buttons.forEach((button) => {
            button.addEventListener('click', () => {
                const activeButton = document.querySelector('.active_btn');
                if (activeButton) {
                    activeButton.classList.remove('active_btn');
                }

                button.classList.add('active_btn');
            });
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('click', () => {
                });
            });
        };
    }, [totalPages]);


    const pageButtons = [
        <button className={`${styles.big_order_btn} ${currentPage === 1 ? styles.disabled_btn : ''}`}
                key="prev" onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}>
            <BtnPrev/>
        </button>,
        <button className={styles.big_order_btn} key={1} onClick={() => goToPage(1)}>
            1
        </button>,
        currentPage > 3 && (
            <span className={styles.big_order_btn} key="ellipsis1">...</span>
        ),
        currentPage > 2 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage - 1} onClick={() => goToPage(currentPage - 1)}>
                {currentPage - 1}
            </button>
        ),
        currentPage > 1 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage} onClick={() => goToPage(currentPage)}>
                {currentPage}
            </button>
        ),
        currentPage < totalPages - 1 && (
            <button className={styles.big_order_btn} key={currentPage + 1} onClick={() => goToPage(currentPage + 1)}>
                {currentPage + 1}
            </button>
        ),
        currentPage < totalPages - 2 && (
            <span key="ellipsis2">...</span>
        ),
        currentPage !== totalPages && (
            <button className={styles.big_order_btn} key={totalPages} onClick={() => goToPage(totalPages)}>
                {totalPages}
            </button>
        ),
        <button className={`${styles.big_order_btn} ${currentPage === totalPages ? styles.disabled_btn : ''}`}
                key="next" onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}>
            <BtnNext/>
        </button>
    ];

    return (
        <div className={styles.big_order_block} style={{display: isBigListOpen ? 'block' : 'none'}}>
            <div className={styles.big_order_list_block}>
                <ul className={`${styles.big_order_list} `}>
                    {bigOrderList.slice(startIndex, endIndex).map((item, i) => (
                        <BigOrderItem key={i} bigOrderList={item}/>
                    ))}
                </ul>

            </div>
            <div className={styles.big_order_btns_gr}>
                {pageButtons}
            </div>
        </div>
    )
}

export default BigOrderList