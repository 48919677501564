import axios from 'axios';
import { getToken } from '../../utils/helpers';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

api.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && localStorage.getItem('token')) {
    localStorage.removeItem('token')
    window.location.href = '/login'
  }
});

export default api;
