import React from "react"
import styles from "./WorkSchedule.module.scss"
import { Calendar, SectionTitle } from "../../../../components"
import classNames from "classnames/bind"
import { WorkTimeCard } from "../WorkTimeCard/WorkTimeCard"
import { useTypedSelector } from "../../../../hooks"
import DateObject from "react-date-object"
import { useDispatch } from "react-redux"
import { changeSelectedDates, selectWorkTime } from "../../store"

const cln = classNames.bind(styles)

export function WorkSchedule(): JSX.Element {
  const selectedDates = useTypedSelector(state => state.home.selectedDates)
  const workTime = useTypedSelector(state => state.home.workTime)
  const dispatch = useDispatch()
  // const { mutate } = useUpdateWorkDate() TODO example mutation function

  function onChangeSelectedDates(dates: DateObject[]) {
    dispatch(changeSelectedDates(dates))
    // mutate({ date: new Date(), isAdded: true, userId: "12551" })
  }

  function changeWorkTime(id: string, isSelected: boolean) {
    dispatch(selectWorkTime({ timeId: id, isSelected }))
  }

  return (
    <section className={cln("work-schedule-section")}>
      <SectionTitle text="" classNames={cln("work-schedule-title")}/>
      <div className={cln("work-schedule-content")}>
        <Calendar
          value={selectedDates}
          onChange={onChangeSelectedDates}
        />
        <WorkTimeCard
          items={workTime}
          orderCount={0}
          classNames={cln("work-time-card-wrapper")}
          onChange={changeWorkTime}
        />
      </div>
    </section>
  )
}
