import './App.css'
import {BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {store, persist} from './store'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {Routing} from './router/Routing'
import {PersistGate} from "redux-persist/integration/react";

const queryClient = new QueryClient()

function App() {
    dayjs.locale('ru')

    return (
        <QueryClientProvider client={queryClient}>
            <div className='App'>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persist}>
                        <BrowserRouter basename='/'>
                            <Routing/>
                            {/*<Router/>*/}
                        </BrowserRouter>
                    </PersistGate>
                </Provider>
            </div>
        </QueryClientProvider>
    )
}

export default App
