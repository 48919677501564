import {React, useState} from "react";
import styles from '../Cleaners/cleaners.module.scss';
import {ReactComponent as BlueCircle} from "../../assets/icons/blueCircle.svg";
import {ReactComponent as GreyCart} from "../../assets/icons/greyCart.svg";
import {ReactComponent as CopyCard} from "../../assets/icons/copyCard.svg";
import Star from "../Star/Star";
import HideCardData from "../HideCardData/HideCardData";
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import StarRating from "../StarRating/StarRating";


const CleanersItem = ({cleanersList}) => {
    const [gradeIndex, setGradeIndex] = useState();
    const grades = [1, 2, 3, 4, 5];
    const activeStar = {
        fill: '#ECE30B'
    };

    const [isDeleteCleaner, setIsDeleteCleaner] = useState(false)
    const [isRemovalReason, setIsRemovalReason] = useState(false)

    const changeGradeIndex = (index) => {
        setGradeIndex(index);
    }

    return (
        <li>
            <div className={styles.cleaner_item}>
                <div>
                    <span>
                        <BlueCircle/>
                    </span>
                </div>
                <div>{cleanersList.name}</div>
                <div>{cleanersList.number}</div>
                <div>{cleanersList.order}</div>
                <div>
                    {/*{cleanersList.rating}*/}
                    <div className={styles.cleaner_container_star}>
                        <span className={styles.cleaner_stars_position}>
                            {/*{*/}
                            {/*    grades.map((grade, index) => (*/}
                            {/*        <Star*/}
                            {/*            index={index}*/}
                            {/*            key={grade}*/}
                            {/*            changeGradeIndex={changeGradeIndex}*/}
                            {/*            style={gradeIndex >= index ? activeStar : {}}*/}
                            {/*        />*/}
                            {/*    ))*/}
                            {/*}*/}
                            <StarRating value={cleanersList.rating}/>
                        </span>

                    </div>
                    {/*<div>*/}
                    <span> {cleanersList.rating}</span>
                           {/*{grades[gradeIndex] ? grades[gradeIndex] : '0'}*/}

                    {/*</div>*/}
                </div>
                <div>{cleanersList.claims}</div>
                <div>{cleanersList.direction}</div>
                <div>{cleanersList.city}</div>
                <div>
                    <span>
                        {/*{cleanersList.card}*/}
                        <HideCardData text={cleanersList.card}/>

                    </span>
                    <span>
                        <button> <CopyCard/> </button>
                    </span>
                </div>
                <div onClick={() => {
                    setIsDeleteCleaner(!isDeleteCleaner)
                }}>
                    {/*<a>*/}
                    <GreyCart/>
                    {/*</a>*/}
                </div>
            </div>
            <div style={{display: isDeleteCleaner ? 'block' : 'none'}}>
                <div className={styles.cleaner_delete_block}>
                    <div>
                        <span>Вы точно хотите удалить {cleanersList.name}?</span>
                    </div>
                    <div className={styles.cleaner_delete}>
                        <div
                            onClick={() => {
                                setIsRemovalReason(true)
                            }}>
                            <button>Да</button>
                        </div>
                        <div onClick={() => {
                            setIsDeleteCleaner(!isDeleteCleaner)
                        }}>
                            <button>Нет</button>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{display: isRemovalReason ? 'block' : 'none'}}>
                <ul className={styles.cleaner_removal_reason}>
                    <li>
                        <div>
                            Укажите причину удаления {cleanersList.name}?
                        </div>
                        <div onClick={() => {
                            setIsRemovalReason(false)
                        }}>
                            <Close/>
                        </div>
                    </li>
                    <li>
                        <input type={"checkbox"}/> <span>Причина</span>
                    </li>
                    <li>
                        <input type={"checkbox"}/> <span>Причина</span>
                    </li>
                    <li>
                        <input type={"checkbox"}/> <span>Причина</span>
                    </li>
                    <li>
                        <input type={"checkbox"}/> <span>Причина</span>
                    </li>
                    <li>
                        <input type={"checkbox"}/> <span>Добавить свою причину</span>
                    </li>
                    <li>
                        <textarea placeholder={'Укажите свою причину'}></textarea>
                    </li>
                    <li>
                        <button>Удалить</button>
                    </li>
                </ul>
            </div>
            <div className={`${isDeleteCleaner ? styles.hiding_block : ''} `}></div>
        </li>

    )
}
export default CleanersItem