import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type AuthState = Partial<{
	token: string
}>

const authState: AuthState = {
	token: undefined
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    setToken:(state, action: PayloadAction<{token: string}>)=> {
      state.token = action.payload.token
    },
	  resetToken: (state) => {
			state.token = undefined
	  }
  },
})

export const {
	setToken,
	resetToken
} = authSlice.actions

export const authReducer = authSlice.reducer