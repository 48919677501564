import React from 'react'
import styles from './../sideBar/sideBar.module.scss'

import CollapseItem from './CollapseItem'

const CollapseList = ({ personalSubMenu, isPersonalOpen, isThin, showPersonalBlock }) => {
  return (
    <ul style={{ display: isPersonalOpen && isThin ? 'block': 'none' }}
        className={`${styles.personal_list} ${isThin ? styles.mini_personalList : ''}`}
        onClick={showPersonalBlock}
    >
      {personalSubMenu.map((item, i) => (
        <CollapseItem key={i} isThin={isThin} personalSubMenu={item} />
      ))}
    </ul>
  )
}

export default CollapseList
