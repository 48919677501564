import React, {useEffect, useRef, useState} from "react";
import styles from './../../styles/home.module.scss'
import plus_white from "../../../src/assets/icons/plus_white.svg";
import sum_debtor from '../../../src/assets/icons/sumDebtor.svg'
import arr_right from "../../../src/assets/icons/arr_right.svg";
import debtor from "../../../src/assets/icons/debtor.svg";
import masters_cl from "../../../src/assets/icons/mastersCleaning.svg";
import cleaners_st from "../../../src/assets/icons/cleanersStatic.svg";
import managers_blue from "../../../src/assets/icons/managersBlue.svg";
import {ReactComponent as Personal} from './../../assets/icons/personal.svg';
import {ReactComponent as Cleaners} from './../../assets/icons/cleaners.svg';
import {ReactComponent as Masters} from './../../assets/icons/masters.svg';
import {ReactComponent as Managers} from './../../assets/icons/managers.svg';
import DebtorEmployeeList from "../DebtorEmployeeList/DebtorEmployeeList";
import bigOrderList from "../BigOrderList/BigOrderList";
import dayjs from "dayjs";
import {Layout} from "../Layout/Layout";
// import {Layout} from '../Layout/Layout';

const StaffPage = (
    // {isShowPersonalOpen}
) => {
    const [isActiveBtn, setIsActiveBtn] = useState(0);
    const handleActiveBtn = (btnIndex) => {
        setIsActiveBtn(btnIndex);
    }
    const [isAddStaff, setIsAddStaff] = useState(false);
    const bthAddStaffBlockRef = useRef(null);
    const addStaffBlockRef = useRef(null);

    const handleAddStaffBlockClick = () => {
        setIsAddStaff(!isAddStaff);

    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                addStaffBlockRef.current &&
                !addStaffBlockRef.current.contains(event.target) &&
                bthAddStaffBlockRef.current &&
                !bthAddStaffBlockRef.current.contains(event.target)
            ) {
                setIsAddStaff(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const staffObject = [
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 23256,
            order: 1,
        },
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 32000,
            order: 1,
        },
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 9000,
            order: 1,
        },
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 2000,
            order: 1,
        },
        {id: 1, name: 'Имя сотрудника', debt: 10000, order: 1,},
        {id: 1, name: 'Имя сотрудника', debt: 23256, order: 1,},
        {id: 1, name: 'Имя сотрудника', debt: 5000, order: 1,},
        {id: 1, name: 'Имя сотрудника', debt: 23256, order: 1,},
        {id: 1, name: 'Имя сотрудника', debt: 39000, order: 1,},
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 5000,
            order: 1,
        },
        {
            id: 1,
            name: 'Имя сотрудника',
            debts: [
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 5000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 12000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 8000,},
                {numberOrder: '1010-125', debtDays: 3, sumDebtor: 2000,},
            ],
            debt: 5500,
            order: 1,
        },
    ]


    return (
        <Layout>
            <div>

                {/*<div className={styles.staff_container}*/}
                {/*    // style={{display: isShowPersonalOpen ? 'block' : 'none'}}*/}
                {/*>*/}

                {/*    <div className={styles.today_block}>*/}
                {/*        /!*<span className={styles.today}>Сегодня</span>*!/*/}
                {/*        <span className={styles.dateToday}>{dayjs(new Date).format('dddd DD MMMM, YYYY  ')}</span>*/}
                {/*    </div>*/}

                <div className={styles.personal_page_menu}>
                    <div>
                        <button className={styles.personal_page_menu_btn}
                                ref={bthAddStaffBlockRef}
                                onClick={handleAddStaffBlockClick}>
                            <img src={plus_white}/>
                            <span className={styles.order_title}>Добавить</span>
                        </button>
                        {isAddStaff && (
                            <div ref={addStaffBlockRef} className={styles.add_staff_block}>
                                <ul>
                                    <li><span> <img src={masters_cl}/> Мастера химчистки</span></li>
                                    <li><span> <img src={cleaners_st}/> Клинеры</span></li>
                                    <li><span> <img src={managers_blue}/> Менеджеры</span></li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className={styles.personal_page_menu_sub}>
                        <div>
                            <span>
                                <img src={sum_debtor}/>
                                <span>
                                    Предельная сумма долга
                                </span>
                            </span>
                        </div>
                        <div>
                            <form>
                                <input className={styles.staff_debt_limit_text} type='text'
                                       placeholder={'Сумма долга'}/>
                                <input className={styles.staff_debt_limit_submit} type='submit'/>
                            </form>
                        </div>
                    </div>
                    <div className={styles.personal_page_menu_sub}>
                        <div className={styles.personal_page_menu_s_pos}>
                            <div>
                                <span> <img src={cleaners_st}/> Клинеры</span>
                            </div>
                            <div>
                                34
                            </div>
                        </div>
                        <div>
                            <a className={styles.check_link} href="#">
                                Перейти <img src={arr_right}/>
                            </a>
                        </div>
                    </div>
                    <div className={styles.personal_page_menu_sub}>
                        <div className={styles.personal_page_menu_s_pos}>
                            <div>
                                <span> <img src={masters_cl}/> Мастера химчистки</span>
                            </div>
                            <div>
                                6
                            </div>
                        </div>
                        <div>
                            <a className={styles.check_link} href="#">
                                Перейти <img src={arr_right}/>
                            </a>
                        </div>
                    </div>
                    <div className={styles.personal_page_menu_sub}>
                        <div className={styles.personal_page_menu_s_pos}>
                            <div>
                                <span> <img src={managers_blue}/> Менеджеры</span>
                            </div>
                            <div>
                                4
                            </div>
                        </div>
                        <div>
                            <a className={styles.check_link} href="#">
                                Перейти <img src={arr_right}/>
                            </a>
                        </div>
                    </div>
                </div>

                <div className={styles.debtor_block}>
                    <div className={styles.debtor_subblock}>
                        <div>
                            <img src={debtor}/> Должники
                        </div>
                        <div>
                            <div
                                className={isActiveBtn === 0 ? styles.activeBtnSP : styles.notActiveBtnSP}
                                onClick={() => handleActiveBtn(0)}
                            >Все
                                <Personal/>
                            </div>
                            <div
                                className={isActiveBtn === 1 ? styles.activeBtnSP : styles.notActiveBtnSP}
                                onClick={() => handleActiveBtn(1)}
                            >Клинеры
                                <Cleaners/>
                            </div>
                            <div
                                className={isActiveBtn === 2 ? styles.activeBtnSP : styles.notActiveBtnSP}
                                onClick={() => handleActiveBtn(2)}
                            >Мастера химчистки
                                <Masters/>
                            </div>
                            <div
                                className={isActiveBtn === 3 ? styles.activeBtnSP : styles.notActiveBtnSP}
                                onClick={() => handleActiveBtn(3)}
                            >Менеджеры
                                <Managers/>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className={`${styles.header_input} ${styles.personal_input1}`}>
                                    <form>
                                        <input type={"text"} placeholder={'Поиск'}/>
                                        <input type={"submit"} value=''/>
                                    </form>

                                </div>
                                <div className={`${styles.header_input} ${styles.personal_input2}`}>
                                    <form>
                                        <input type={"text"} placeholder={'Поиск по дате'}/>
                                        <input type={"submit"} value=''/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DebtorEmployeeList
                        staffObject={staffObject}
                        // isBigListOpen={isBigListOpen}
                    />
                </div>
                {/*</div>*/}

            </div>
        </Layout>

    )
}

export default StaffPage;