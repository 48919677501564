import { redirect } from "react-router-dom";

type Props = {
  to: string
}

export function Redirect({ to }: Props): null {
  redirect(to)
  return null
}
