import React, {useState, useEffect, useRef} from 'react';
import styles from './../../styles/home.module.scss';
import './debtor.module.scss';
import {ReactComponent as BtnPrev} from "../../assets/icons/button_prev.svg";
import {ReactComponent as BtnNext} from "../../assets/icons/button_next.svg";
import {ReactComponent as MessageAll} from "../../assets/icons/massageAll.svg";
import {ReactComponent as ArrGroup} from "../../assets/icons/arrGroup.svg";
import {ReactComponent as ArrMore} from "../../assets/icons/arrMore.svg";
import {ReactComponent as ArrLess} from "../../assets/icons/arrLess.svg";
import DebtorEmployeeItem from "./DebtorEmployeeItem";

const DebtorEmployeeList = ({
                                staffObject,
                                // isBigListOpen
                            }) => {

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = React.useState(1);
    const totalPages = Math.ceil(staffObject.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    }
    useEffect(() => {
        const buttons = document.querySelectorAll('.big_order_btn');

        buttons.forEach((button) => {
            button.addEventListener('click', () => {
                const activeButton = document.querySelector('.active_btn');
                if (activeButton) {
                    activeButton.classList.remove('active_btn');
                }

                button.classList.add('active_btn');
            });
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('click', () => {
                });
            });
        };
    }, [totalPages]);


    const pageButtons = [
        <button className={`${styles.big_order_btn} ${currentPage === 1 ? styles.disabled_btn : ''}`}
                key="prev" onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}>
            <BtnPrev/>
        </button>,
        <button className={styles.big_order_btn} key={1} onClick={() => goToPage(1)}>
            1
        </button>,
        currentPage > 3 && (
            <span className={styles.big_order_btn} key="ellipsis1">...</span>
        ),
        currentPage > 2 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage - 1} onClick={() => goToPage(currentPage - 1)}>
                {currentPage - 1}
            </button>
        ),
        currentPage > 1 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage} onClick={() => goToPage(currentPage)}>
                {currentPage}
            </button>
        ),
        currentPage < totalPages - 1 && (
            <button className={styles.big_order_btn} key={currentPage + 1} onClick={() => goToPage(currentPage + 1)}>
                {currentPage + 1}
            </button>
        ),
        currentPage < totalPages - 2 && (
            <span key="ellipsis2">...</span>
        ),
        currentPage !== totalPages && (
            <button className={styles.big_order_btn} key={totalPages} onClick={() => goToPage(totalPages)}>
                {totalPages}
            </button>
        ),
        <button className={`${styles.big_order_btn} ${currentPage === totalPages ? styles.disabled_btn : ''}`}
                key="next" onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}>
            <BtnNext/>
        </button>
    ];

    const [isFilterDebtorVisible, setIsFilterDebtorVisible] = useState(false);
    const firstDebtorBlockRef = useRef(null);
    const secondDebtorBlockRef = useRef(null);
    const [isFilterOrderVisible, setIsFilterOrderVisible] = useState(false);
    const firstOrderBlockRef = useRef(null);
    const secondOrderBlockRef = useRef(null);
    const handleDebtorBlockClick = () => {
        setIsFilterDebtorVisible(true);
    };
    const handleOrderBlockClick = () => {
        setIsFilterOrderVisible(true);
    };

    useEffect(() => {
        const handleDebtorOutsideClick = (event) => {
            if (
                secondDebtorBlockRef.current &&
                !secondDebtorBlockRef.current.contains(event.target) &&
                firstDebtorBlockRef.current &&
                !firstDebtorBlockRef.current.contains(event.target)
            ) {
                setIsFilterDebtorVisible(false);
            }
        };

        document.addEventListener('mousedown', handleDebtorOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleDebtorOutsideClick);
        };
    }, []);

    useEffect(() => {
        const handleOrderOutsideClick = (event) => {
            if (
                secondOrderBlockRef.current &&
                !secondOrderBlockRef.current.contains(event.target) &&
                firstOrderBlockRef.current &&
                !firstOrderBlockRef.current.contains(event.target)
            ) {
                setIsFilterOrderVisible(false);
            }
        };

        document.addEventListener('mousedown', handleOrderOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOrderOutsideClick);
        };
    }, []);
    return (
        <div className={styles.big_order_block}
            // style={{display: isBigListOpen ? 'block' : 'none'}}
        >
            <div className={styles.big_order_list_block}>
                <ul className={`${styles.big_order_list} `}>
                    <li className={`${styles.debtors_title} `}>
                        <div className={styles.debtors_columns_position}>
                            <div>ID</div>
                            <div>ФИО</div>
                            <div className={styles.debt_filter}>
                                <div
                                    ref={firstDebtorBlockRef}
                                    onClick={handleDebtorBlockClick}>
                                    <span>Задолженность <ArrGroup/></span>
                                </div>
                                {isFilterDebtorVisible && (
                                    <div ref={secondDebtorBlockRef}>
                                        <ul>
                                            <li>
                                                <span>Больше <ArrMore/></span>
                                            </li>
                                            <li>
                                                <span>Меньше <ArrLess/></span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className={styles.debt_filter}>
                                <div
                                    ref={firstOrderBlockRef}
                                    onClick={handleOrderBlockClick}>
                                    <span>Заказы <ArrGroup/></span></div>
                                {isFilterOrderVisible && (
                                    <div ref={secondOrderBlockRef}>
                                        <ul>
                                            <li>
                                                <span>Больше <ArrMore/></span>
                                            </li>
                                            <li>
                                                <span>Меньше <ArrLess/></span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div>Разрешение</div>
                            <div>Уведомить</div>
                            <div>
                                {/*<form action="">*/}
                                <input type="checkbox" className={styles.debtors_title_checkbox_custom}
                                       id={styles.debtors_ttl_id_checkbox}/>
                                <label forHtml='debtors_ttl_id_checkbox'></label>
                                {/*</form>*/}
                            </div>
                            <div>
                                <MessageAll/>
                            </div>
                        </div>
                    </li>
                    {staffObject.slice(startIndex, endIndex).map((item, i) => (
                        <DebtorEmployeeItem key={i} staffObject={item}/>
                    ))}
                </ul>

            </div>
            <div className={`${styles.big_order_btns_gr} ${styles.debtor}`}>
                {pageButtons}
            </div>
        </div>
    )
}

export default DebtorEmployeeList