import styles from "./WorkTimeCard.module.scss"
import classNames from "classnames/bind"
import { TimeCard } from "../../types"

const cln = classNames.bind(styles)

type Props = {
  orderCount?: number
  items: TimeCard[]
  classNames?: string
  onChange?: (timeId: string, isSelected: boolean) => void
}

export function WorkTimeCard(props: Props): JSX.Element {
  const { orderCount, items, classNames, onChange } = props

  function onChangeTime(id: string, isSelected: boolean) {
    onChange?.(id, isSelected)
  }

  return (
    <div className={cln("container", classNames)}>
      <h5 className={cln("title")}>Клиент берёт <span className={cln("title-count")}>{orderCount}</span> заказа</h5>
      <div className={cln("times-wrapper")}>
        {items.map(({ time, isSelected, id}) => (
          <div className={cln("time-card", isSelected && "selected")} key={id} onClick={() => onChangeTime(id, !isSelected)}>
            <span className={cln("time-card-text")}>{time}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
