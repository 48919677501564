import React, {useEffect, useRef, useState} from "react";
import styles from './cleanerCard.module.scss';
import {ReactComponent as BlueArrLeft} from "../../assets/icons/arr_blue_left.svg";
import cleanerPhoto from '../../assets/icons/cleanerPhoto.png';
import StarRating from "../StarRating/StarRating";
import {ReactComponent as CleanersSt} from "../../../src/assets/icons/cleanersStatic.svg";
import {ReactComponent as BluePhone} from "../../../src/assets/icons/bluePhone.svg";
import {ReactComponent as ArrDownInCircle} from "../../../src/assets/icons/arrDownInCircle.svg";
import {ReactComponent as BlueMassage} from "../../../src/assets/icons/blueMassage.svg";
import {ReactComponent as BlackList} from "../../../src/assets/icons/blackListInCircle.svg";
import {ReactComponent as RedCart} from "../../../src/assets/icons/redCart.svg";
import {ReactComponent as BlueCircle} from "../../../src/assets/icons/blueCircle.svg";
import {ReactComponent as BankCard} from "../../../src/assets/icons/bankCard.svg";
import {ReactComponent as Contract} from "../../../src/assets/icons/contract.svg";
import {ReactComponent as Pay} from "../../../src/assets/icons/pay.svg";
import {ReactComponent as Claims} from "../../../src/assets/icons/claims.svg";
import {ReactComponent as Comission} from "../../../src/assets/icons/comission.svg";
import {ReactComponent as Documents} from "../../../src/assets/icons/documents.svg";
import {ReactComponent as SelfEmployd} from "../../../src/assets/icons/selfEmloyd.svg";
import {ReactComponent as Comment} from "../../../src/assets/icons/comment.svg";
import {ReactComponent as CopyBtn} from "../../../src/assets/icons/copyBtn.svg";
import {ReactComponent as BluePen} from "../../../src/assets/icons/bluePen.svg";
import {ReactComponent as CloseBlue} from "../../../src/assets/icons/closeBlue.svg";
import {ReactComponent as QuestionMark} from "../../../src/assets/icons/question_mark.svg";
import {ReactComponent as BlueRectangle} from "../../../src/assets/icons/blue_rectangle.svg";
import {ReactComponent as ClearBlueRectangle} from "../../../src/assets/icons/clear_blue_rectangle.svg";

import {ReactComponent as EditBtn} from "../../../src/assets/icons/editBtn.svg";
import SelectCustom from "../Select/SelectCustom";
import StaffForm from "../StaffForm/StaffForm";
import styled from "styled-components";
import {WorkSchedule} from "../../unit/home/components";
import {Layout} from "../Layout/Layout";

const CleanerCard = () => {
    const MainSelect = styled("div")`
      font-family: sans-serif;
      background: white;
    `;

    const DropDownContainer = styled("div")`
      width: 12vw;
      margin: 0 auto;
    `;

    const DropDownHeader = styled("div")`
      //margin-bottom: 0.8em;
      padding: 0.6em 2em 0.6em 1em;
      //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
      font-weight: 500;
      font-size: 0.83vw;
      color: black;
      background-color: white !important;
      margin: 0 auto;
      position: relative;

      svg {
        position: absolute !important;
        //margin-left: 4.5vw;
        right: 10px;
      }
    `;

    const DropDownListContainer = styled("div")`
      position: absolute;
      z-index: 100;
      width: 12.1vw;
      right: 1.7vw;
    `;

    const DropDownList = styled("ul")`
      padding: 0;
      margin: 0;
      padding-left: 1em;
      background: #ffffff;
      border-left: 1px solid #04B0FB;
      border-right: 1px solid #04B0FB;
      border-bottom: 1px solid #04B0FB;
      box-sizing: border-box;
      color: #999999;
      font-size: 0.83vw;
      font-weight: 500;

      &:first-child {
        //padding-top: 0.8em;
        padding-top: 1.2vw;
        padding-bottom: 0.2vw;
      }
    `;

    const ListItem = styled("li")`
      list-style: none;
      margin-bottom: 0.8em;
      justify-content: left !important;
      line-height: 1.2vw !important;
      height: 100% !important;

      &:hover {
        color: #04B0FB;
      }
    `;
    const options = ["Ежедневная", "Еженедельная", "2 раза в месяц", "1 раза в месяц"];

    const dataAboutCleaner = {
        name: 'Маслечко Светлана Викторовна',
        number: '+7 916 000 00 00 ',
        mail: 'maslech_sv@mail.ru',
        completed_orders: 175,
        date: '12.05.2021',
        city: 'Москва/ Проспект Мира',
    }
    const personalData = {
        birth_date: '05.05.1978',
        passport: '4509 009857',
        issue_date: '01.05.2008',
        issued_by: 'ОВД г. Ростов-На-Дону, Михайловский район',
        nationality: 'Российская Федерация',
        registration_address: 'г. Москва, ул. Маршала Чуйкова, д. 12, кв. 57',
        residence_address: 'г. Москва, ул. Маршала Чуйкова, д. 12, кв. 57',
        card_details: '5336 6903 8110 9612',
        account_number: '000 000 000 000',
        bank_BIC: '000 000 000'
    }
    const cityList = [
        {city: 'Российская Федерация'}, {city: 'Украина'}, {city: 'Белоруссия'},
        {city: 'Армения'}, {city: 'Киргизия'}, {city: 'Казахстан'},
        {city: 'Таджикистан'}, {city: 'Узбекистан'}, {city: 'Молдавия'},
    ]
    const valueRating = '4.0';

    const [isRequisites, setIsRequisites] = useState(false)
    const [isCheckedCheckbox, setIsCheckedCheckbox] = useState(false);
    const [checkboxClass, setCheckboxClass] = useState('');

    const [isNationality, setIsNationality] = useState(false);
    const firstNationalityBlockRef = useRef(null);
    const secondNationalityBlockRef = useRef(null);
    useEffect(() => {
        const handleNationalityOutsideClick = (event) => {
            if (
                secondNationalityBlockRef.current &&
                !secondNationalityBlockRef.current.contains(event.target) &&
                firstNationalityBlockRef.current &&
                !firstNationalityBlockRef.current.contains(event.target)
            ) {
                setIsNationality(false);
            }
        };

        document.addEventListener('mousedown', handleNationalityOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleNationalityOutsideClick);
        };
    }, []);

    const [isBlackList, setIsBlackList] = useState(false);
    const firstBlackListRef = useRef(null);
    const secondBlackListRef = useRef(null);
    useEffect(() => {
        const handleBlackListOutsideClick = (event) => {
            if (
                secondBlackListRef.current &&
                !secondBlackListRef.current.contains(event.target) &&
                firstBlackListRef.current &&
                !firstBlackListRef.current.contains(event.target)
            ) {
                setIsBlackList(false);
            }
        };

        document.addEventListener('mousedown', handleBlackListOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleBlackListOutsideClick);
        };
    }, []);

    const [isRedCart, setIsRedCart] = useState(false);
    const firstRedCartRef = useRef(null);
    const secondRedCartRef = useRef(null);
    useEffect(() => {
        const handleRedCartOutsideClick = (event) => {
            if (
                secondRedCartRef.current &&
                !secondRedCartRef.current.contains(event.target) &&
                firstRedCartRef.current &&
                !firstRedCartRef.current.contains(event.target)
            ) {
                setIsRedCart(false);
            }
        };

        document.addEventListener('mousedown', handleRedCartOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleRedCartOutsideClick);
        };
    }, []);

    const handleCheckboxChange = () => {
        setIsCheckedCheckbox(!isCheckedCheckbox);
        if (isCheckedCheckbox) {
            setCheckboxClass('');
        } else {
            setCheckboxClass(`${styles.checkbox_checked}`);
        }
    };


    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.back_to_list}>
                    <a><BlueArrLeft/>Обратно к списку</a>
                </div>
                <div className={styles.cleaner_card_block}>
                    <div className={styles.cleaner_card_data}>
                        <div className={styles.cleaner_photo}>
                            <div>
                                <img
                                    // width={190} height={190}
                                    src={cleanerPhoto}/>
                            </div>
                            <div>
                                <div>
                                    <StarRating value={valueRating}/>
                                </div>
                                <div>
                                    <span>{valueRating}</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.cleaner_info}>
                            <div className={styles.cleaner_info_name}>
                                <div><CleanersSt/> <span>{dataAboutCleaner.name}</span></div>
                                <div>
                                    <div><BluePhone/> <span
                                        className={styles.cleaner_info_name_number}>{dataAboutCleaner.number}</span>
                                        <span className={styles.cleaner_info_name_select}><ArrDownInCircle/></span>
                                    </div>
                                    <div><BlueMassage/> <span
                                        className={styles.cleaner_info_name_mail}>{dataAboutCleaner.mail} </span>
                                        <span className={styles.cleaner_info_name_select}><ArrDownInCircle/></span>
                                    </div>
                                </div>
                                <div>
                                    <div ref={firstBlackListRef}>
                                        <div onClick={() => {
                                            setIsBlackList(true)
                                        }}
                                        >
                                            <BlackList/>
                                            {isBlackList && (
                                                <div className={styles.black_list_block}
                                                     ref={secondBlackListRef}
                                                     style={{display: isBlackList ? 'block' : 'none'}}>
                                                    <ul>
                                                        <li>Причина</li>
                                                        <li>1</li>
                                                        <li>1</li>
                                                        <li>1</li>
                                                        <li>
                                                            <button>Добавить причину</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div ref={firstRedCartRef}>
                                        <div onClick={() => {
                                            setIsRedCart(true)
                                        }}>
                                            <RedCart/>
                                            {isRedCart && (
                                                <div className={styles.black_list_block}
                                                     ref={secondRedCartRef}
                                                     style={{display: isRedCart ? 'block' : 'none'}}>
                                                    <ul>
                                                        <li>Причина</li>
                                                        <li>2</li>
                                                        <li>2</li>
                                                        <li>2</li>
                                                        <li>
                                                            <button>Добавить причину</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div><EditBtn/></div>
                                </div>
                            </div>
                            <div className={styles.info_options_about_cleaner}>
                                <div><BlueCircle/> Работает</div>
                                <div>Клинер</div>
                                <div><input type={"checkbox"}/><span>Пылесос</span></div>
                                <div><input type={"checkbox"}/><span>Парогенератор</span></div>
                            </div>
                            <div className={styles.data_about_cleaner}>
                                <ul>
                                    <li>Выполненных заказов <span>{dataAboutCleaner.completed_orders}</span></li>
                                    <li>Дата оформления <span>{dataAboutCleaner.date}</span></li>
                                    <li>Город <span>{dataAboutCleaner.city}</span></li>
                                    <li>
                                        <div>Выезд за МКАД <input type={"checkbox"}/></div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={styles.work_schedule}>
                        <div>
                            <div> График работы <QuestionMark/>

                                <div className={styles.work_schedule_hidden}>
                                    <span>Рабочий день и рабочие часы: <BlueRectangle/> Рабочее время <ClearBlueRectangle/> Не рабочее время</span>
                                </div>

                            </div>
                            <div><EditBtn/></div>
                        </div>
                        <WorkSchedule/>
                    </div>

                    <div className={styles.personal_data_block}>
                        <div className={styles.personal_data_title}>
                            <span>Персональные данные</span>
                            <span><EditBtn/></span>
                        </div>
                        <div className={styles.personal_data}>
                            <div>
                                <ul>
                                    <li> Дата рождения</li>
                                    <li> Паспорт</li>
                                    <li> Дата выдачи</li>
                                    <li> Кем выдан</li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><span>{personalData.birth_date}</span></li>
                                    <li><span>{personalData.passport}</span></li>
                                    <li><span>{personalData.issue_date}</span></li>
                                    <li><span>{personalData.issued_by}</span></li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li> Гражданство</li>
                                    <li> Адрес регистрации</li>
                                    <li> Адрес проживания</li>
                                    <li>
                                        <input type={"checkbox"}/> Адрес регистрации и фактический совпадают
                                    </li>
                                </ul>
                            </div>
                            <div ref={firstNationalityBlockRef}>
                                <ul>
                                    <li onClick={() => {
                                        setIsNationality(!isNationality)
                                    }}>
                                        <span>{personalData.nationality} <ArrDownInCircle/></span>
                                        {isNationality && (
                                            <div ref={secondNationalityBlockRef}
                                                 className={styles.city_list_style}
                                                 style={{display: isNationality ? 'block' : 'none'}}>
                                                <ul>
                                                    {cityList.map((item, i) =>
                                                        <li key={i}>
                                                            {item.city}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </li>
                                    <li><span>{personalData.registration_address}</span></li>
                                    <li><span>{personalData.residence_address}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className={styles.documents_blocks}>
                        <div className={styles.documents_blocks_official}>
                            <div>
                                <ul>
                                    <li>
                                        <div><BankCard/> <span> Банковская карта </span></div>
                                        <div><CopyBtn/></div>

                                    </li>
                                    <li>{personalData.card_details}</li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><Contract/> <span>Договор №827001</span></li>
                                    <li>
                                        <button onClick={() => {
                                            setIsRequisites(!isRequisites)
                                        }}>Скачать
                                        </button>
                                    </li>
                                </ul>

                                <div className={styles.requisites_block}
                                     style={{display: isRequisites ? 'block' : 'none'}}>
                                    <ul>
                                        <li>
                                            <span>Реквизиты</span>
                                            <span>
                                            <span><CopyBtn/></span>
                                            <span><BluePen/></span>
                                            <span onClick={() => {
                                                setIsRequisites(false)
                                            }}
                                            ><CloseBlue/></span>
                                        </span>

                                        </li>
                                        <li>
                                            <div>ФИО</div>
                                            <div>{dataAboutCleaner.name}</div>
                                        </li>
                                        <li>
                                            <div>Номер счета</div>
                                            <div>{personalData.account_number}</div>
                                        </li>
                                        <li>
                                            <div>БИК банка</div>
                                            <div>{personalData.bank_BIC}</div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div>
                                <ul>
                                    <li><Pay/> <span>Выплаты</span></li>
                                    <li>
                                        <SelectCustom

                                            Main={MainSelect} DropDownContainer={DropDownContainer}
                                            DropDownHeader={DropDownHeader}
                                            DropDownListContainer={DropDownListContainer}
                                            DropDownList={DropDownList} ListItem={ListItem}
                                            options={options}
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li>

                                        <div><Claims/> <span> Претензии</span></div>
                                        <div> 2</div>
                                    </li>
                                    <li>
                                        <button>Подробнее</button>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li><Comission/> <span>Комиссия</span></li>
                                    <li>
                                        <form action="">
                                            <input className={styles.comission_input} type={"text"}
                                                   placeholder={'Сумма комиссии'}/>
                                            <input className={styles.comission_submit} type={"submit"}/>
                                        </form>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.documents_blocks_official_comment}>
                            <div>
                                <ul>
                                    <li><Documents/> <span>Документы</span></li>
                                    <li>
                                        <button>Добавить</button>
                                    </li>
                                </ul>
                            </div>
                            <div className={` ${checkboxClass}`}>
                                <ul>
                                    <li>
                                        <div><SelfEmployd/> <span>Самозанятая</span></div>
                                        <div><input type={"checkbox"} checked={isCheckedCheckbox}
                                                    onChange={handleCheckboxChange}/></div>
                                    </li>
                                    <li>
                                        <button>Реквизиты</button>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                {/*<ul>*/}
                                {/*    <li><Comment/><span>  Комментарий</span></li>*/}
                                {/*    <li>*/}
                                {/*        <form action="">*/}
                                {/*            <input className={`${styles.comission_input} ${styles.comission_input_second}`}*/}
                                {/*                   type={"text"}/>*/}
                                {/*            <input className={styles.comission_submit_second} type={"submit"}/>*/}
                                {/*        </form>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                                <div>
                                    <Comment/>
                                </div>
                                <div className={styles.cleaner_comment}>
                                    <textarea name="" id={styles.cleaner_comment_text}
                                              placeholder="Комментарий"></textarea>
                                    <button className={styles.cleaner_comment_btn}>Отправить</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {/*<StaffForm/>*/}
            </div>
        </Layout>
    )
}
export default CleanerCard
