import {React, useState, useRef, useEffect} from "react";
import styles from './cleaners.module.scss';
import styleInput from '../../../src/styles/home.module.scss';
import dayjs from "dayjs";
import plus_white from "../../assets/icons/plus_white.svg";
import cleaners_st from "../../assets/icons/cleanersStatic.svg";
import masters_cl from "../../assets/icons/mastersCleaning.svg";
import arr_right from "../../assets/icons/arr_right.svg";
import managers_blue from "../../assets/icons/managersBlue.svg";
import {ReactComponent as OptionCleaners} from "../../assets/icons/optionCleaners.svg";
import CleanersList from "../CleanersList/CleanersList";
import {Layout} from "../Layout/Layout";

const Cleaners = () => {

    const cleanersList = [
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0001',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0002',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0003',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0004',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0005',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0006',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0007',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0008',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0009',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0010',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0000',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0000',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0000',
        },
        {
            status: 0,
            name: 'Имя сотрудника',
            number: '+7 (958) 48-32-579',
            order: 100,
            rating: 4,
            claims: 3,
            direction: 'Офисы/квартиры ',
            city: 'Москва',
            card: '0000 0000 0000 0000',
        },
    ]

    const [isTableSittings, setIsTableSittings] = useState(false);
    const firstTableSittings = useRef(null);
    const secondTableSittings = useRef(null);

    const handleTableSittings = () => {
        setIsTableSittings(true);
    };
    useEffect(() => {
        const handleOrderOutsideClick = (event) => {
            if (
                secondTableSittings.current &&
                !secondTableSittings.current.contains(event.target) &&
                firstTableSittings.current &&
                !firstTableSittings.current.contains(event.target)
            ) {
                setIsTableSittings(false);
            }
        };

        document.addEventListener('mousedown', handleOrderOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOrderOutsideClick);
        };
    }, []);

    return (
        <Layout>
            <div className={styles.container}>
                {/*<div className={styles.today_block}>*/}
                {/*    /!*<span className={styles.today}>Сегодня</span>*!/*/}
                {/*    <span className={styles.dateToday}>{dayjs(new Date).format('dddd DD MMMM, YYYY  ')}</span>*/}
                {/*</div>*/}

                <div className={styles.cleaners_data}>
                    <div>
                        <div>
                            <button className={styles.add_cleaner}>
                                <span className={styles.add_cleaner_title}> <img src={plus_white}/> Добавить</span>
                            </button>
                        </div>
                        <div>
                            <span><img src={cleaners_st}/>  <span>Клинеры</span></span>
                            <span> 34 </span>
                        </div>
                        <div>
                            <div>
                                <span> <img src={masters_cl}/> <span>Мастера химчистки</span> </span>
                                <span> 6 </span>
                            </div>
                            <div>
                            <span>
                                <a className={styles.check_data} href="#"> Перейти <img src={arr_right}/></a>
                            </span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span> <img src={managers_blue}/> <span>Админ. персонал</span> </span>
                                <span> 4 </span>
                            </div>
                            <div>
                            <span>
                                <a className={styles.check_data} href="#"> Перейти <img src={arr_right}/></a>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.cleaners_list}>
                    <div className={styles.cleaners_position_input}>
                        <div className={styleInput.header_input}>
                            <form action="">
                                <input type='text' className={styles.cleaners_input} placeholder={'Поиск'}/>
                                <input type='submit' className={styles.cleaners_submit}/>
                            </form>
                        </div>
                        <div>
                            <div>
                                <button>
                                    График отпусков
                                </button>
                            </div>
                            <div>
                                <button
                                    ref={firstTableSittings}
                                    onClick={handleTableSittings}>
                                    <OptionCleaners/>
                                </button>
                            </div>
                            {isTableSittings && (
                                <div ref={secondTableSittings}
                                     className={styles.cleaners_settings_table_block}
                                >
                                    <span>Настройки таблицы</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <CleanersList cleanersList={cleanersList}/>

                </div>
                <div className={`${isTableSittings ? styles.hiding_block : ''} `}></div>
            </div>
        </Layout>

    );
}
export default Cleaners