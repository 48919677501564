import React, {useState} from 'react';
import styles from '../Cleaners/cleaners.module.scss';

const HideCardData = ({text}) => {
    const [isHidden, setIsHidden] = useState(true);

    const handleToggle = () => {
        setIsHidden(!isHidden);
    };

    const hiddenText = isHidden ? text.slice(0, 12).replace(/./g, 'X').replace(/(.{4})/g, '$1 ') + text.slice(14, 20) : text;
    return (
        <div>
            <span className={styles.hide_card_data_span} onClick={handleToggle}>
                {`${hiddenText} `}</span>
        </div>
    );
};

export default HideCardData;