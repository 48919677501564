import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProfile } from '../../services/api/profile'

const initialState = {
  name: "",
  roleName: "",
  userId: '',
  roleId: '',
  email: '',
  loading: true,
  burgerMenu:false
}

export const fetchProfile = createAsyncThunk(
  'profile/get',
  async () => {
    const response = await getProfile()
    return response
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    openBurgerMenu:(state)=>{
      state.burgerMenu = true
    },
    closeBurgerMenu:(state)=>{
      state.burgerMenu = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.name = action.payload.name
      state.roleName = action.payload.role_name
      state.userId = action.payload.id
      state.email = action.payload.email
      state.loading = action.payload.false
    })
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.loading = action.payload.false
    })
  },
})

export const { openBurgerMenu, closeBurgerMenu } = profileSlice.actions

export const profileLoadingSelector =(state)=>state.profile.loading
export const profileSelector =(state)=>state.profile
export const burgerMenuSelector =(state)=>state.profile.burgerMenu

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = profileSlice.actions

export default profileSlice.reducer