import React, {useState} from "react";
import Select from "react-select";
// import "./App.css";
import {ReactComponent as ArrDown} from "../../assets/icons/arrDownInCircle.svg";

const customStyles = {
    option: (provided) => ({
        ...provided,
        backgroundColor: 'white',
        color: '#999999',
        outline: 'none',
        fontSize: '0.83vw',
        // padding: 20,
        // borderBottom: "1px solid #04B0FB",
        "&:hover": {
            // backgroundColor: "#7fffd4",
            // color: "#0000ff",
            // outline: 'none',
            // border: 'none'
        },

    }),
    control: (provided) => ({
        ...provided,
        height: 30,
        marginBlock: 0,
        backgroundColor: "white",
        borderRadius: 0,
        border: 'none',
        outline: 'none',
        boxShadow: 'none',
        borderBottom: "1px solid #04B0FB",
        fontSize: '0.83vw',
        // minHeight: 0,
        "&:focus": {
            border: 0,
            outline: 0,
            // borderColor: "#7fffd4",
            // outline: 'none',
            // border: 'none'
        },
        "&:hover": {
            border: 0,
            outline: 0,
            borderBottom: "1px solid #04B0FB",
            // borderColor: "#7fffd4",
            // outline: 'none',
            // border: 'none'
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#999999',
        fontSize: '0.83vw',
        paddingTop: 5,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#999999',
        fontSize: '0.83vw',
        padding: 0,
        margin: 0,
        paddingTop: 5,
        // lineHeight:20,
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        display: 'flex',
        // alignItems: 'end',
    }),

};

function SelectNationality() {
    const options = [
        {value: 'Российская Федерация', label: 'Российская Федерация'},
        {value: "Украина", label: 'Украина'},
        {value: "Белоруссия", label: 'Белоруссия'},
        {value: "Армения", label: 'Армения'},
        {value: "Киргизия", label: 'Киргизия'},
        {value: "Казахстан", label: 'Казахстан'},
        {value: "Таджикистан", label: 'Таджикистан'},
        {value: "Узбекистан", label: 'Узбекистан'},
        {value: "Молдавия", label: 'Молдавия'},
    ];

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    return (
        <div className="wrapper">
            {/*<Select options={options} />*/}

            {/*<h4>Customizing the Select Element</h4>*/}
            <Select
                options={options}
                value={selectedOption}
                onChange={handleChange}
                placeholder={'Гражданство'}
                styles={customStyles}
                components={{
                    IndicatorSeparator: null,
                    DropdownIndicator: () => <ArrDown width={20}/>,
                }}
            />
        </div>
    );
}

export default SelectNationality;
