import type {PropsWithChildren} from 'react'
import {HomeHeader} from "../../unit/home/components/HomeHeader";
import SideBar from "../sideBar/sideBar";

import mainStyle from '../../styles/home.module.scss'
import dayjs from "dayjs";
import React from "react";

export function Layout(props: PropsWithChildren) {


    return (
    <>
        <SideBar/>
        <div className={mainStyle.container}>
            <HomeHeader/>
            <div className={mainStyle.today_block}>
                <span className={mainStyle.dateToday}>{dayjs(new Date()).format('dddd DD MMMM, YYYY')}</span>
            </div>
            {props.children}
        </div>
    </>

    )
}
