import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { authReducer } from './slices/auth'
import { profileSlice } from './slices/profile'
import { statsSlice } from './slices/stats'
import { tasksSlice } from './slices/tasks'
import { homeReducer } from '../unit/home/store'
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

const persistConfig = {
	key: 'root',
	storage: storageSession,
	whitelist: ['system', 'auth']
}

const logger: any = createLogger({
	level: 'info',
	collapsed: true,
	duration: true,
	logErrors: true
})

const rootReducer = combineReducers({
	profile: profileSlice.reducer,
	stats: statsSlice.reducer,
	tasks: tasksSlice.reducer,
	home: homeReducer,
	auth: authReducer
})
export const persistedRootReducer = persistReducer(persistConfig, rootReducer)
export const store = configureStore({
	reducer: persistedRootReducer,
	middleware: gDM => gDM().concat(logger, thunk),
	devTools: true
})
export const persist = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>



