import React from "react"
import styles from "./SectionTitle.module.scss"
import classNames from "classnames/bind"

const cln = classNames.bind(styles)

type Props = {
  text: string | undefined
  classNames?: string
}

export function SectionTitle(props: Props): JSX.Element {
  const { text, classNames } = props
  return <h3 className={cln("section-title", classNames)}>{text}</h3>
}
