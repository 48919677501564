import axios from "axios";

const apiUrl: string = process.env.REACT_APP_API_URL ?? "http://localhost:3000";

const instance = axios.create({
  baseURL: apiUrl,
});

axios.defaults.baseURL = `${process.env.BASE_URL}/api`;

export const isProductionBuild = process.env.NODE_ENV == "production";

export const appUrl: string = isProductionBuild
  ? process.env.APP_URL ?? "http://localhost:3000"
  : "http://localhost:3000";


export default instance;
