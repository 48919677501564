import React from "react";
import styles from './../sideBar/sideBar.module.scss'

const DocumentsItem = ({documentsSubMenu, isThin}) => {
    return (
        <li className={`${styles.personal_item} ${isThin ? styles.mini_personal : ''}`}>
            <div className={styles.menu_icon}>{documentsSubMenu.icon} </div>
            <span className={styles.menu_title}>{documentsSubMenu.title}</span>
        </li>
    )

}

export default DocumentsItem