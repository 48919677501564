import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DateObject from "react-date-object";
import { TimeCard } from "../types";
import { getWorkTimes } from "../utils";

type HomeType = {
  selectedDates: DateObject[]
  workTime: TimeCard[]
}
const initialState: HomeType = {
  selectedDates: [],
  workTime: getWorkTimes(),
};

const homeSlice = createSlice({
  name: "home",
  initialState: initialState,
  reducers: {
    changeSelectedDates: (state, action: PayloadAction<DateObject[]>) => {
      state.selectedDates = action.payload
    },
    selectWorkTime: (state, action: PayloadAction<{ timeId: string, isSelected: boolean }>) => {
      state.workTime = state.workTime.map(time =>
        time.id === action.payload.timeId
          ? { ...time, isSelected: action.payload.isSelected }
          : time
      )
    },
  },
})

export const { changeSelectedDates, selectWorkTime } = homeSlice.actions
export const homeReducer = homeSlice.reducer
