import React from "react";
import styles from '../CleanerCard/cleanerCard.module.scss';
import {ReactComponent as BlueArrLeft} from "../../assets/icons/arr_blue_left.svg";
import cleanerPhoto from '../../assets/icons/avaNoFace.png';
import StarRating from "../StarRating/StarRating";
import {ReactComponent as BluePhone} from "../../../src/assets/icons/bluePhone.svg";
import {ReactComponent as ArrDownInCircle} from "../../../src/assets/icons/arrDownInCircle.svg";
import {ReactComponent as BlueMassage} from "../../../src/assets/icons/blueMassage.svg";
import {ReactComponent as BlackList} from "../../../src/assets/icons/blackListInCircle.svg";
import {ReactComponent as RedCart} from "../../../src/assets/icons/redCart.svg";
import {ReactComponent as BlueCircle} from "../../../src/assets/icons/blueCircle.svg";
import {ReactComponent as WhitePen} from "../../../src/assets/icons/whitePen.svg";

import {ReactComponent as BankCard} from "../../../src/assets/icons/bankCard.svg";
import {ReactComponent as Contract} from "../../../src/assets/icons/contract.svg";
import {ReactComponent as Pay} from "../../../src/assets/icons/pay.svg";
import {ReactComponent as Claims} from "../../../src/assets/icons/claims.svg";
import {ReactComponent as Comission} from "../../../src/assets/icons/comission.svg";
import {ReactComponent as Documents} from "../../../src/assets/icons/documents.svg";
import {ReactComponent as Comment} from "../../../src/assets/icons/comment.svg";
import {ReactComponent as CopyBtn} from "../../../src/assets/icons/copyBtn.svg";

import SelectCustom from "../Select/SelectCustom";
import styled from "styled-components";
import SelectNationality from "../SelectNationality/SelectNationality";
import {WorkSchedule} from "../../unit/home/components";
import {Layout} from "../Layout/Layout";

const StaffForm = () => {
    const Main = styled("div")`
      font-family: sans-serif;
      background: white;
    `;

    const DropDownContainer = styled("div")`
      width: 12vw;
      margin: 0 auto;
      cursor: pointer;
    `;

    const DropDownHeader = styled("div")`
      //margin-bottom: 0.8em;
      padding: 0.6em 2em 0.6em 1em;
      //box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
      font-weight: 500;
      font-size: 0.83vw;
      color: black;
      background-color: white !important;
      margin: 0 auto;
      position: relative;

      svg {
        position: absolute !important;
        //margin-left: 4.5vw;
        right: 10px;
      }
    `;

    const DropDownListContainer = styled("div")`
      position: absolute;
      z-index: 100;
      width: 12.1vw;
      right: 1.7vw;
      cursor: pointer;
    `;

    const DropDownList = styled("ul")`
      padding: 0;
      margin: 0;
      padding-left: 1em;
      background: #ffffff;
      border-left: 1px solid #04B0FB;
      border-right: 1px solid #04B0FB;
      border-bottom: 1px solid #04B0FB;
      box-sizing: border-box;
      color: #999999;
      font-size: 0.83vw;
      font-weight: 500;
      cursor: pointer;

      &:first-child {
        //padding-top: 0.8em;
        padding-top: 1.2vw;
        padding-bottom: 0.2vw;
      }
    `;

    const ListItem = styled("li")`
      list-style: none;
      margin-bottom: 0.8em;
      justify-content: left !important;
      line-height: 1.2vw !important;
      height: 100% !important;

      &:hover {
        color: #04B0FB;
      }
    `;
    const optionsPay = ["Ежедневная", "Еженедельная", "2 раза в месяц", "1 раза в месяц"];

    const optionsProf = ["Мастер химчистки", "Клинер", "Менеджер"];
    const valueRating = 0;


    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.container_form}>
                    <div className={styles.back_to_list}>
                        <a><BlueArrLeft/>Обратно к списку</a>
                    </div>
                    <div className={styles.cleaner_form_block}>
                        <div className={styles.cleaner_card_data}>
                            <div className={styles.cleaner_photo}>
                                <div>

                                    <img
                                        // width={190} height={190}
                                        src={cleanerPhoto}/>
                                </div>
                                <div>
                                    <div>
                                        <StarRating value={valueRating}/>
                                    </div>
                                    <div>
                                        <span>{valueRating}.0</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cleaner_info}>
                                <div className={`${styles.cleaner_info_name} ${styles.input}`}>
                                    <div>
                                <span><input
                                    className={styles.cleaner_form_name} type={"text"}
                                    placeholder={'Имя работника'}/>
                                </span>
                                    </div>
                                    <div className={styles.data_group_form}>
                                        <div>
                                            <BluePhone/>
                                            <span
                                                className={styles.cleaner_info_name_number}>
                                         <input className={styles.cleaner_form_number} type={"text"}/>
                                    </span>
                                            <span className={`${styles.cleaner_info_name_select} ${styles.form_left}`}>
                                        <ArrDownInCircle/>
                                    </span>
                                        </div>
                                        <div>
                                            <BlueMassage/>
                                            <span
                                                className={styles.cleaner_info_name_mail}>
                                        <input className={styles.cleaner_form_mail} type={"text"}/>
                                    </span>
                                            <span
                                                className={`${styles.cleaner_info_name_select} ${styles.form_right}`}><ArrDownInCircle/></span>
                                        </div>
                                    </div>
                                    <div>
                                        <div><BlackList/></div>
                                        <div><RedCart/></div>
                                        <div><WhitePen/></div>
                                    </div>
                                </div>
                                <div className={`${styles.info_options_about_cleaner} ${styles.form}`}>
                                    <div><BlueCircle/> Работает</div>
                                    <div>

                                        <SelectCustom
                                            Main={Main} DropDownContainer={DropDownContainer}
                                            DropDownHeader={DropDownHeader}
                                            DropDownListContainer={DropDownListContainer}
                                            DropDownList={DropDownList} ListItem={ListItem}
                                            options={optionsProf}/>
                                    
                                    </div>
                                    <div>
                                        <div><input type={"checkbox"}/></div>
                                        <div>Пылесос</div>
                                    </div>
                                    <div>
                                        <div><input type={"checkbox"}/></div>
                                        <div>Парогенератор</div>
                                    </div>
                                </div>
                                <div className={styles.data_about_cleaner}>
                                    <ul>
                                        <li>Выполненных заказов <span>0</span></li>
                                        <li>Дата оформления <span>17.08.2023</span></li>
                                        <li><input className={styles.cleaner_form_city} placeholder={"Город"}
                                                   type={"text"}/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div className={styles.personal_data_form}>
                            <span>Персональные данные</span>
                            <div>
                                <div>
                                    <div><input type={"text"} placeholder={'Дата рождения'}/></div>
                                    <div><input type={"text"} placeholder={'Паспорт'}/></div>
                                    <div><input type={"text"} placeholder={'Дата выдачи '}/></div>
                                    <div><input type={"text"} placeholder={'Кем выдан'}/></div>
                                </div>
                                <div>
                                    <div><SelectNationality/></div>
                                    <div><input type={"text"} placeholder={'Адрес регистрации '}/></div>
                                    <div><input type={"text"} placeholder={'Адрес проживания '}/></div>
                                    <div>
                                        <div><input type={"checkbox"}/></div>
                                        <div>Адрес регистрации и фактический совпадают</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.documents_blocks}>
                            <div className={styles.documents_blocks_official}>
                                <div>
                                    <ul>
                                        <li>
                                            <div><BankCard/> <span> Банковская карта </span></div>
                                            <div><CopyBtn/></div>

                                        </li>
                                        <li>
                                            <input type={"number"}/>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><Contract/> <span>Договор №827001</span></li>
                                        <li>
                                            <button
                                                // onClick={() => {setIsRequisites(!isRequisites)}}
                                            >
                                                Загрузить
                                            </button>
                                        </li>
                                    </ul>

                                    {/*<div className={styles.requisites_block} style={{display: isRequisites ? 'block' : 'none'}}>*/}
                                    {/*    <ul>*/}
                                    {/*        <li>*/}
                                    {/*            <span>Реквизиты</span>*/}
                                    {/*            <span>*/}
                                    {/*                <span><CopyBtn/></span>*/}
                                    {/*                <span><BluePen/></span>*/}
                                    {/*                <span onClick={() => {*/}
                                    {/*                    setIsRequisites(false)*/}
                                    {/*                }}*/}
                                    {/*                ><CloseBlue/></span>*/}
                                    {/*            </span>*/}

                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <div>ФИО</div>*/}
                                    {/*            <div>{dataAboutCleaner.name}</div>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <div>Номер счета</div>*/}
                                    {/*            <div>{personalData.account_number}</div>*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*            <div>БИК банка</div>*/}
                                    {/*            <div>{personalData.bank_BIC}</div>*/}
                                    {/*        </li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}

                                </div>
                                <div>
                                    <ul>
                                        <li><Pay/> <span>Выплаты</span></li>
                                        <li>
                                            <SelectCustom
                                                Main={Main} DropDownContainer={DropDownContainer}
                                                DropDownHeader={DropDownHeader}
                                                DropDownListContainer={DropDownListContainer}
                                                DropDownList={DropDownList} ListItem={ListItem}
                                                options={optionsPay}
                                            />
                                        </li>
                                    </ul>
                                </div>
                                <div className={styles.staff_form_claims}>
                                    <ul>
                                        <li>

                                            <div><Claims/> <span> Претензии</span></div>
                                            <div> 0</div>
                                        </li>
                                        <li>
                                            <button>Подробнее</button>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul>
                                        <li><Comission/> <span>Комиссия</span></li>
                                        <li>
                                            <form action="">
                                                <input className={styles.comission_input} type={"text"}
                                                       placeholder={'Сумма комиссии'}/>
                                                <input className={styles.comission_submit} type={"submit"}/>
                                            </form>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`${styles.documents_blocks_official_comment} ${styles.form_comment}`}>
                                <div>
                                    <ul>
                                        <li><Documents/> <span>Документы</span></li>
                                        <li>
                                            <button>Загрузить</button>
                                        </li>
                                    </ul>
                                </div>
                                {/*<div></div>*/}
                                {/*<div className={` ${checkboxClass}`}>*/}
                                {/*    <ul>*/}
                                {/*        <li>*/}
                                {/*            <div><SelfEmployd/> <span>Самозанятая</span></div>*/}
                                {/*            <div><input type={"checkbox"} checked={isCheckedCheckbox}*/}
                                {/*                        onChange={handleCheckboxChange}/></div>*/}
                                {/*        </li>*/}
                                {/*        <li>*/}
                                {/*            <button>Реквизиты</button>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                                <div>
                                    <div>
                                        <Comment/>
                                    </div>
                                    <div className={styles.cleaner_comment}>
                                    <textarea name="" id={styles.cleaner_comment_text}
                                              placeholder="Комментарий"></textarea>
                                        <button className={styles.cleaner_comment_btn}>Отправить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.work_schedule}>
                            <div>График работы</div>
                            <WorkSchedule/>
                        </div>
                        <div className={styles.staff_form_btn}>
                            <button>Сохранить</button>
                            <button>Закрыть</button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export default StaffForm;