import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {AuthGuard} from "./guards/AuthGuard";
import {HomeLoadablePage} from "../unit/home/page/Loadable";
import LoginPage from "../pages/login";
import NotFoundPage from "../pages/404";
import SignUpPage from "../pages/signUp";
import CreateOrder from "../pages/createOrder";
import CleanerCard from "../components/CleanerCard/CleanerCard";
import StaffForm from "../components/StaffForm/StaffForm";
// import CleanerCard from "../pages/CleanerCard/CleanerCard";
import MainWrapper from "../wrappers/MainWrapper";
import {HomeHeader} from "../unit/home/components/HomeHeader";
import styles from "../styles/home.module.scss";
import dayjs from "dayjs";
import Cleaners from "../components/Cleaners/Cleaners";
import StaffPage from "../components/StaffPage/StaffPage";

export function Routing(): JSX.Element {

    return (
// <main>
//     {/*<HomeHeader/>*/}
//     <MainWrapper>
//
//         <Routes>
//             <Route path="/login" element={<LoginPage/>}/>
//             <Route path="/404" element={<NotFoundPage/>}/>
//             <Route path="/register" element={<SignUpPage/>}/>
//             <Route path="/" element={<AuthGuard><HomeLoadablePage/></AuthGuard>}/>
//             <Route path="/createOrder" element={<CreateOrder/>}/>
//             <Route path="/cleanerCard" element={<CleanerCard/>}/>
//             <Route path="/staffForm" element={<StaffForm/>}/>
//         </Routes>
//         {/*</HomeHeader>*/}
//     </MainWrapper>
// </main>

    // <MainWrapper>
                <Routes>
                    <Route path="/login" element={<LoginPage/>}/>

                    <Route path="/404" element={<NotFoundPage/>}/>
                    <Route path="/register" element={<SignUpPage/>}/>
                    <Route path="/" element={<AuthGuard><HomeLoadablePage/></AuthGuard>}/>
                    <Route path="/createOrder" element={<CreateOrder/>}/>

                    <Route path="/cleanerCard" element={<CleanerCard/>}/>
                    <Route path="/staffForm" element={<StaffForm/>}/>
                    <Route path="/staffPage" element={<StaffPage/>}/>
                    <Route path="/cleaners" element={<Cleaners/>}/>
                </Routes>
    // </MainWrapper>

    );
};
