import React from "react";
import styles from '../Cleaners/cleaners.module.scss';

const CityItem = ({citiesList}) => {
    return (
        <li>
            <span> <input type={"checkbox"}/>
                <span>{citiesList.city}</span>
                {/*{citiesList.city}*/}
            </span>
        </li>
    )
}
export default CityItem