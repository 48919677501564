import React from "react";
import CityItem from './CityItem';
import styles from '../Cleaners/cleaners.module.scss';

const CitiesList = ({citiesList}) => {
    return (
        <div id={styles.cleaners_cities_list_position}>
            <div>
                <ul>
                    <li>
                        <span> <input type={"checkbox"}/><span>Все</span></span>
                    </li>
                    {citiesList.map((item, i) => (
                        <CityItem key={i} citiesList={item}/>
                    ))}
                </ul>
            </div>

            {/*<div>*/}
                <div>
                    <button>Применить</button>
                </div>
                <div>
                    <button>Сбросить</button>
                </div>
            {/*</div>*/}
        </div>
    )
}
export default CitiesList