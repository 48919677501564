import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import instance from '../../../api/instance'
import { setToken } from '../../../store/slices/auth'
import Mutations from '../mutations'

type LoginParams = {
	email: string
	password: string
}

type LoginResponse = {
	data: {
		name: string
		role: string
		token: string
	}
	details: string
	result: string
}

export function useLogin(): UseMutationResult<AxiosResponse<LoginResponse>, AxiosError<{
	details?: string
}>, LoginParams, unknown> {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	
	return useMutation<AxiosResponse<LoginResponse>, AxiosError<{ details?: string }>, LoginParams, unknown>({
			mutationKey: [Mutations.LOGIN],
			mutationFn: (params) => login(params),
			onSuccess: ({ data }) => {
				localStorage.setItem('token', data.data.token)
				dispatch(setToken({ token: data.data.token }))
				navigate('/')
			},
			retry: false
		}
	)
}

const login = async (params: LoginParams): Promise<AxiosResponse<LoginResponse>> => {
	return await instance.post('login', params)
}
