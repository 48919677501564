import React from 'react'
import styles from './../styles/CreateOrder.module.scss'
import assignments from './../assets/icons/service/assignments.svg'
import balcony from './../assets/icons/service/balcony.svg'
import blinds from './../assets/icons/service/blinds.svg'
import cabinet from './../assets/icons/service/cabinet.svg'
import cell from './../assets/icons/service/cell.svg'
import chandelier from './../assets/icons/service/chandelier.svg'
import cleaner from './../assets/icons/service/cleaner.svg'
import conditioner from './../assets/icons/service/conditioner.svg'
import curtains from './../assets/icons/service/curtains.svg'
import dishes from './../assets/icons/service/dishes.svg'
import fridge from './../assets/icons/service/fridge.svg'
import hardchandelier from './../assets/icons/service/hardchandelier.svg'
import keys from './../assets/icons/service/keys.svg'
import linen from './../assets/icons/service/linen.svg'
import microwave from './../assets/icons/service/microwave.svg'
import oven from './../assets/icons/service/oven.svg'
import plate from './../assets/icons/service/plate.svg'
import steam from './../assets/icons/service/steam.svg'
import teapod from './../assets/icons/service/teapod.svg'
import trash from './../assets/icons/service/trash.svg'
import tray from './../assets/icons/service/tray.svg'
import vacuum from './../assets/icons/service/vacuum.svg'
import wall from './../assets/icons/service/wall.svg'
import wardrobe from './../assets/icons/service/wardrobe.svg'
import wash from './../assets/icons/service/wash.svg'
import wc from './../assets/icons/service/wc.svg'
import window from './../assets/icons/service/window.svg'
import hood from './../assets/icons/service/hood.svg'
import iron from './../assets/icons/service/iron.svg'
import MainWrapper from '../wrappers/MainWrapper'

const items = [
  { icon: oven, title: 'Помыть внутри духовки', price: 0 },
  { icon: fridge, title: 'Помыть внутри холодильника', price: 399 },
  { icon: iron, title: 'Погладить белье', price: 399 },
  { icon: microwave, title: 'Внутри микроволновки', price: 399 },
  { icon: cabinet, title: 'Внутри кухонных шкафов', price: 399 },
  { icon: chandelier, title: 'Помыть люстру', price: 399 },
  { icon: blinds, title: 'Протирка жалюзи', price: 399 },
  { icon: balcony, title: 'Уборка на балконе', price: 399 },
  { icon: linen, title: 'Замена постельного белья', price: 399 },
  { icon: hood, title: 'Вымыть вытяжку', price: 399 },
  { icon: wardrobe, title: 'Внутри гардероба', price: 399 },
  { icon: window, title: 'Окна на балконе', price: 399 },
  { icon: wc, title: 'Второй сан узел', price: 399 },
  { icon: assignments, title: 'Особые поручения', price: 399 },
  { icon: tray, title: 'Помыть лоток питомца', price: 399 },
  { icon: vacuum, title: 'Привезти пылесос', price: 399 },
  { icon: dishes, title: 'Помыть посуду', price: 399 },
  { icon: wash, title: 'Стирка белья', price: 399 },
  { icon: trash, title: 'Строительный мусор', price: 399 },
  { icon: keys, title: 'Забрать ключи', price: 399 },
  { icon: cleaner, title: 'Опытный клинер', price: 399 },
  { icon: wall, title: 'Протереть стены', price: 399 },
  { icon: teapod, title: 'Помыть чайник', price: 399 },
  { icon: keys, title: 'Доставить ключи', price: 399 },
  { icon: conditioner, title: 'Протереть кондиционер', price: 399 },
  { icon: steam, title: 'Взять парогенератор', price: 399 },
  { icon: curtains, title: 'Снять шторы', price: 399 },
  { icon: curtains, title: 'Повесить шторы', price: 399 },
  { icon: hardchandelier, title: 'Помыть сложную люстру', price: 399 },
  { icon: plate, title: 'Почистить плиту', price: 399 },
  { icon: cell, title: 'Помыть москитную сетку', price: 399 },
]

const CreateOrder = () => {
  return (
    <MainWrapper>
      <div className={styles.container}>
        <p className={styles.text}>Выберите услуги которые будут отображаться при создании нового заказа, и установите цены Активируйте опцию кликнув на иконку.</p>
        <div className={styles.menu}>
          <span className={styles.menu_item}>Активировать</span>
          <span className={styles.menu_item}>Укажите стоимость услуги</span>
          <span className={styles.menu_item}>Сохранить</span>
          <span className={styles.menu_item}>Редактировать</span>
        </div>
        {/* <div className={styles.service_list}>
          <div className={styles.service_item}>
            <img src={oven} />
            <span className={styles.service_title}>Помыть внутри духовки</span>
            <span className={styles.service_price}>150 руб</span>
          </div>
        </div> */}
        <div className={styles.service_container}>
          {items.map(item => (
            <div className={styles.service_list}>
              <div className={styles.service_item}>
                <img src={item.icon} />
                <span className={styles.service_title}>{item.title}</span>
                <span className={styles.service_price}>{item.price} руб</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </MainWrapper>
  )
}

export default CreateOrder
