import { TimeCard } from "../types";

export function getWorkTimes(): TimeCard[] {
  return [
    { id: "1", time: "08:00", isSelected: false },
    { id: "2", time: "09:00", isSelected: false },
    { id: "3", time: "10:00", isSelected: false },
    { id: "4", time: "11:00", isSelected: false },
    { id: "5", time: "12:00", isSelected: false },
    { id: "6", time: "13:00", isSelected: false },
    { id: "7", time: "14:00", isSelected: false },
    { id: "8", time: "15:00", isSelected: false },
    { id: "9", time: "16:00", isSelected: false },
    { id: "10", time: "17:00", isSelected: false },
    { id: "11", time: "18:00", isSelected: false },
    { id: "12", time: "19:00", isSelected: false },
  ]
}
