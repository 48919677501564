import React, {useEffect, useRef, useState} from "react";
import stylesClean from '../Cleaners/cleaners.module.scss';
import styles from './../../styles/home.module.scss';
import {ReactComponent as BtnPrev} from "../../assets/icons/button_prev.svg";
import {ReactComponent as BtnNext} from "../../assets/icons/button_next.svg";
import {ReactComponent as MessageAll} from "../../assets/icons/massageAll.svg";
import {ReactComponent as ArrGroup} from "../../assets/icons/arrGroup.svg";
import {ReactComponent as CartCleaners} from "../../assets/icons/cartCleaners.svg";
import CleanersItem from "./CleanersItem";
import {ReactComponent as ArrMore} from "../../assets/icons/arrMore.svg";
import {ReactComponent as ArrLess} from "../../assets/icons/arrLess.svg";
import CitiesList from "../CitiesList/CitiesList";
import '../DebtorEmployeeList/debtor.module.scss'
import {ReactComponent as Close} from "../../assets/icons/close.svg";
import {ReactComponent as ArrDownWhite} from "../../assets/icons/arrDownWhite.svg";
// import DebtorEmployeeItem from "../DebtorEmployeeList/DebtorEmployeeItem";

const CleanersList = ({cleanersList}) => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = React.useState(1);
    const totalPages = Math.ceil(cleanersList.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;

    const goToPage = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    }
    useEffect(() => {
        const buttons = document.querySelectorAll('.big_order_btn');

        buttons.forEach((button) => {
            button.addEventListener('click', () => {
                const activeButton = document.querySelector('.active_btn');
                if (activeButton) {
                    activeButton.classList.remove('active_btn');
                }

                button.classList.add('active_btn');
            });
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('click', () => {
                });
            });
        };
    }, [totalPages]);

    const pageButtons = [
        <button className={`${styles.big_order_btn} ${currentPage === 1 ? styles.disabled_btn : ''}`}
                key="prev" onClick={() => goToPage(currentPage - 1)}
                disabled={currentPage === 1}>
            <BtnPrev/>
        </button>,
        <button className={styles.big_order_btn} key={1} onClick={() => goToPage(1)}>
            1
        </button>,
        currentPage > 3 && (
            <span className={styles.big_order_btn} key="ellipsis1">...</span>
        ),
        currentPage > 2 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage - 1} onClick={() => goToPage(currentPage - 1)}>
                {currentPage - 1}
            </button>
        ),
        currentPage > 1 && currentPage < totalPages && (
            <button className={styles.big_order_btn} key={currentPage} onClick={() => goToPage(currentPage)}>
                {currentPage}
            </button>
        ),
        currentPage < totalPages - 1 && (
            <button className={styles.big_order_btn} key={currentPage + 1} onClick={() => goToPage(currentPage + 1)}>
                {currentPage + 1}
            </button>
        ),
        currentPage < totalPages - 2 && (
            <span key="ellipsis2">...</span>
        ),
        currentPage !== totalPages && (
            <button className={styles.big_order_btn} key={totalPages} onClick={() => goToPage(totalPages)}>
                {totalPages}
            </button>
        ),
        <button className={`${styles.big_order_btn} ${currentPage === totalPages ? styles.disabled_btn : ''}`}
                key="next" onClick={() => goToPage(currentPage + 1)}
                disabled={currentPage === totalPages}>
            <BtnNext/>
        </button>
    ];

    const [isFilterName, setIsFilterName] = useState(false);
    const firstFilterNameBlock = useRef(null);
    const secondFilterNameBlock = useRef(null);

    const handleFilterNameBlockClick = () => {
        setIsFilterName(true);
    };

    useEffect(() => {
        const handleFilterBlockOutsideClick = (event) => {
            if (
                secondFilterNameBlock.current &&
                !secondFilterNameBlock.current.contains(event.target) &&
                firstFilterNameBlock.current &&
                !firstFilterNameBlock.current.contains(event.target)
            ) {
                setIsFilterName(false);
            }
        };

        document.addEventListener('mousedown', handleFilterBlockOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterBlockOutsideClick);
        };
    }, []);

    const [isFilterOrder, setIsFilterOrder] = useState(false);
    const firstFilterOrderBlock = useRef(null);
    const secondFilterOrderBlock = useRef(null);

    const handleFilterOrderBlockClick = () => {
        setIsFilterOrder(true);
    };

    useEffect(() => {
        const handleFilterOrderBlockOutsideClick = (event) => {
            if (
                secondFilterOrderBlock.current &&
                !secondFilterOrderBlock.current.contains(event.target) &&
                firstFilterOrderBlock.current &&
                !firstFilterOrderBlock.current.contains(event.target)
            ) {
                setIsFilterOrder(false);
            }
        };

        document.addEventListener('mousedown', handleFilterOrderBlockOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterOrderBlockOutsideClick);
        };
    }, []);

    const [isFilterRating, setIsFilterRating] = useState(false);
    const firstFilterRatingBlock = useRef(null);
    const secondFilterRatingBlock = useRef(null);

    const handleFilterRatingBlockClick = () => {
        setIsFilterRating(true);
    };

    useEffect(() => {
        const handleFilterRatingBlockOutsideClick = (event) => {
            if (
                secondFilterRatingBlock.current &&
                !secondFilterRatingBlock.current.contains(event.target) &&
                firstFilterRatingBlock.current &&
                !firstFilterRatingBlock.current.contains(event.target)
            ) {
                setIsFilterRating(false);
            }
        };

        document.addEventListener('mousedown', handleFilterRatingBlockOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterRatingBlockOutsideClick);
        };
    }, []);

    const [isFilterClaims, setIsFilterClaims] = useState(false);
    const firstFilterClaimsBlock = useRef(null);
    const secondFilterClaimsBlock = useRef(null);

    const handleFilterClaimsBlockClick = () => {
        setIsFilterClaims(true);
    };

    useEffect(() => {
        const handleFilterClaimsBlockOutsideClick = (event) => {
            if (
                secondFilterClaimsBlock.current &&
                !secondFilterClaimsBlock.current.contains(event.target) &&
                firstFilterClaimsBlock.current &&
                !firstFilterClaimsBlock.current.contains(event.target)
            ) {
                setIsFilterClaims(false);
            }
        };

        document.addEventListener('mousedown', handleFilterClaimsBlockOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterClaimsBlockOutsideClick);
        };
    }, []);


    const [isFilterCities, setIsFilterCities] = useState(false);
    const firstFilterCitiesBlock = useRef(null);
    const secondFilterCitiesBlock = useRef(null);
    const handleFilterCitiesBlockClick = () => {
        setIsFilterCities(true);
    };

    useEffect(() => {
        const handleFilterClaimsBlockOutsideClick = (event) => {
            if (
                secondFilterCitiesBlock.current &&
                !secondFilterCitiesBlock.current.contains(event.target) &&
                firstFilterCitiesBlock.current &&
                !firstFilterCitiesBlock.current.contains(event.target)
            ) {
                setIsFilterCities(false);
            }
        };

        document.addEventListener('mousedown', handleFilterClaimsBlockOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleFilterClaimsBlockOutsideClick);
        };
    }, []);

    const citiesList = [
        {city: 'Москва'}, {city: 'Санкт-Петербург'}, {city: 'Архангельск'}, {city: 'Великий Новгород'}, {city: 'Владимир'}, {city: 'Волгоград'}, {city: 'Екатеринбург'}, {city: 'Иркутск'}, {city: 'Йошкар-Ола'},
    ]

    const [isDeleteCleaner, setIsDeleteCleaner] = useState(false)
    const [isRemovalReason, setIsRemovalReason] = useState(false)

    return (
        <div
            // style={{display: isBigListOpen ? 'block' : 'none'}}
        >
            <div>
                <ul className={`${stylesClean.cleaners_block} `}>
                    <li className={`${stylesClean.cleaners_title} `}>
                        <div className={stylesClean.cleaners_columns_position}>
                            <div>Статус</div>
                            <div ref={firstFilterNameBlock}
                                 onClick={handleFilterNameBlockClick}>
                                <div>
                                    <span>
                                        ФИО
                                    </span>
                                </div>
                                {isFilterName && (
                                    <div ref={secondFilterNameBlock} id={stylesClean.cleaners_filter_name}>
                                        <div>
                                            <input type={"checkbox"}/><span> Без сортировки</span>
                                        </div>
                                        <div>
                                            <input type={"checkbox"}/><span> По алфавиту</span>
                                        </div>
                                        <div>
                                            <button>Применить</button>
                                        </div>
                                        <div>
                                            <button>Сбросить</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div>Телефон</div>

                            <div ref={firstFilterOrderBlock}
                                 onClick={handleFilterOrderBlockClick}>
                                <div>
                                    <span>Заказы</span>
                                </div>
                                {isFilterOrder && (
                                    <div ref={secondFilterOrderBlock}
                                         className={stylesClean.cleaners_filter_rating}>
                                        <ul>
                                            <li>
                                                <span>Больше <span><ArrMore/></span></span>
                                            </li>
                                            <li>
                                                <span>Меньше <span><ArrLess/></span></span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div ref={firstFilterRatingBlock}
                                 onClick={handleFilterRatingBlockClick}>
                                <div>
                                    <span>Рейтинг</span>
                                </div>
                                {isFilterRating && (
                                    <div ref={secondFilterRatingBlock}
                                         className={stylesClean.cleaners_filter_rating}>
                                        <ul>
                                            <li>
                                                <span>Больше <span><ArrMore/></span></span>
                                            </li>
                                            <li>
                                                <span>Меньше <span><ArrLess/></span></span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div ref={firstFilterClaimsBlock}
                                 onClick={handleFilterClaimsBlockClick}>
                                <div>
                                    <span>Претензии</span>
                                </div>
                                {isFilterClaims && (
                                    <div ref={secondFilterClaimsBlock}
                                         className={stylesClean.cleaners_filter_rating}>
                                        <ul>
                                            <li>
                                                <span>Больше <span><ArrMore/></span></span>
                                            </li>
                                            <li>
                                                <span>Меньше <span><ArrLess/></span></span>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div>Направление</div>
                            <div ref={firstFilterCitiesBlock}
                                 onClick={handleFilterCitiesBlockClick}>

                                <div>
                                    <span>Город <ArrDownWhite/></span>
                                </div>
                                {isFilterCities && (
                                    <div ref={secondFilterCitiesBlock}
                                         className={stylesClean.cleaners_cities_list}>
                                        <CitiesList citiesList={citiesList}/>
                                    </div>
                                )}
                            </div>
                            <div>Карта</div>
                            <div
                                onClick={() => {
                                    setIsDeleteCleaner(!isDeleteCleaner)
                                }}>
                                {/*<a href={'#'}>*/}
                                <CartCleaners/>
                                {/*</a>*/}
                            </div>

                        </div>

                    </li>
                    {cleanersList.slice(startIndex, endIndex).map((item, i) => (
                        <CleanersItem key={i} cleanersList={item}/>
                    ))}
                </ul>
                <div style={{display: isDeleteCleaner ? 'block' : 'none'}}>
                    <div className={stylesClean.cleaner_delete_block}>
                        <div>
                            <span>Вы точно хотите удалить Всех Сотрудников?</span>
                        </div>
                        <div className={stylesClean.cleaner_delete}>
                            <div onClick={() => {
                                setIsRemovalReason(true)}}>
                                <button>Да</button>
                            </div>
                            <div onClick={() => {
                                setIsDeleteCleaner(!isDeleteCleaner)
                            }}>
                                <button>Нет</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{display: isRemovalReason ? 'block' : 'none'}}>
                   <ul className={stylesClean.cleaner_removal_reason}>
                       <li>
                           <div>
                               Укажите причину удаления Всех Сотрудников?
                           </div>
                           <div onClick={()=>{setIsRemovalReason(false)}}>
                               <Close/>
                           </div>
                       </li>
                       <li>
                           <input type={"checkbox"}/> <span>Причина</span>
                       </li>
                       <li>
                           <input type={"checkbox"}/> <span>Причина</span>
                       </li>
                       <li>
                           <input type={"checkbox"}/> <span>Причина</span>
                       </li>
                       <li>
                           <input type={"checkbox"}/> <span>Причина</span>
                       </li>
                       <li>
                           <input type={"checkbox"}/> <span>Добавить свою причину</span>
                       </li>
                       <li>
                           <textarea placeholder={'Укажите свою причину'}></textarea>
                       </li>
                       <li>
                           <button>Удалить</button>
                       </li>
                   </ul>
                </div>

            </div>
            <div className={`${styles.big_order_btns_gr} ${styles.debtor} ${stylesClean.cleaners_list_btn}`}>
                {pageButtons}
            </div>
            <div className={`${isDeleteCleaner ? stylesClean.hiding_block : ''} `}></div>
        </div>
    )
}

export default CleanersList