import api from '..';
import { DEFAULT_ERROR_TEXT } from '../../../constants';

export async function login({ username, password }) {
    try {
        const res = await api.post('login', {
            email: username,
            password,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function signUp({ name, password, email }) {
    try {
        const res = await api.post('register', {
            name: name,
            password,
            password_repeat: password,
            email,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function isAuth() {
    if (!localStorage.getItem('token')) {
        return false;
    }
    return true;
}
