import React from 'react'
import styles from './../sideBar/sideBar.module.scss'

import DocumentsItem from './DocumentsItem'

const DocumentsList = ({ documentsSubMenu, isDocumentsOpen, isThin }) => {
    return (
        <ul style={{ display: isDocumentsOpen && isThin ? 'block': 'none' }} className={`${styles.personal_list} ${isThin ? styles.mini_personalList : ''}`}>
            {documentsSubMenu.map((item, i) => (
                <DocumentsItem key={i} isThin={isThin} documentsSubMenu={item} />
            ))}
        </ul>
    )
}

export default DocumentsList