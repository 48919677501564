import React, {useState, useEffect, useRef} from 'react';
import styles from './../../styles/home.module.scss';
import './debtor.module.scss';
import {ReactComponent as ArrDown} from "../../assets/icons/arrDownDebtor.svg";
import {ReactComponent as MessageOne} from "../../assets/icons/massageAll.svg";
import {ReactComponent as Close} from "../../assets/icons/close.svg";

const DebtorEmployeeItem = ({staffObject}) => {
    // {debEmpList}
    const [isSocialBlockVisible, setSocialBlockVisible] = useState(false);
    const indexBlockRef = useRef(null);
    const socialBlockRef = useRef(null);
    const [isDebtorsBlockList, setIsDebtorsBlockList] = useState(false);
    // const [debtorsBlockList, setDebtorsBlockList] = useState({top: 0, left: 0});

    const handleDebtorsListClick = () => {
        // const block = document.getElementById('list_debtors_position');
        //
        // // Получаем размеры экрана
        // const screenWidth = window.innerWidth;
        // const screenHeight = window.innerHeight;
        //
        // // Получаем размеры и положение блока
        // const blockWidth = block.offsetWidth;
        // const blockHeight = block.offsetHeight;
        // const blockRect = block.getBoundingClientRect();
        //
        // // Получаем новое положение блока
        // let newTop = blockRect.top;
        // let newLeft = blockRect.left;
        //
        // // Если блок выходит за пределы экрана по горизонтали
        // if (blockRect.left + blockWidth > screenWidth) {
        //     newLeft = 10;
        // }
        //
        // // Если блок выходит за пределы экрана по вертикали
        // if (blockRect.top + blockHeight > screenHeight) {
        //     newTop = 10;
        // }
        //
        // setDebtorsBlockList({top: newTop, left: newLeft});

        setIsDebtorsBlockList(!isDebtorsBlockList)
    }
    const handleIndexBlockClick = () => {
        setSocialBlockVisible(true);

    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                socialBlockRef.current &&
                !socialBlockRef.current.contains(event.target) &&
                indexBlockRef.current &&
                !indexBlockRef.current.contains(event.target)
            ) {
                setSocialBlockVisible(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const interestConversion = Math.floor((staffObject.debt / 40000) * 100);
    let backgroundColorDebtor = '';
    let debtItemPosition = '';
    let debtPositionEl = '';
    if (interestConversion < 25) {
        backgroundColorDebtor = '#04B0FB'
    } else {
        backgroundColorDebtor = '#F7B77E'
    }
    if (interestConversion < 90) {
        debtItemPosition = 'absolute'
        debtPositionEl = '10%'
    } else {
        debtItemPosition = 'relative'
        debtPositionEl = '27%'
    }

    const containerStyles = {
        height: '1.75vw',
        width: '31.25vw',
        backgroundColor: "#DEF3FD",
    }

    const fillerStyles = {
        height: '100%',
        width: `${interestConversion}%`,
        backgroundColor: `${backgroundColorDebtor}`,
        textAlign: 'right'
    }

    const labelStyles = {
        padding: 5,
        color: '#999999',
        fontWeight: '500',
        fontSize: '1vw',
        position: `${debtItemPosition}`,
        top: `${debtPositionEl}`,
    }

    return (
        <li className={`${styles.deb_empl_itm} `}>
            <div className={`${styles.debtors_column_position}`}
                 style={{
                     zIndex: isDebtorsBlockList ? '40' : '',
                     background: isDebtorsBlockList ? 'white' : '',
                     position: isDebtorsBlockList ? 'relative' : '',
                 }}>
                <div className={styles.debtor_id}>{staffObject.id}</div>
                <div className={styles.debtor_name}>{staffObject.name}</div>
                <div className={styles.debtor_debt}
                     onClick={handleDebtorsListClick}>
                    {/*{debEmpList.debt}*/}
                    <div style={containerStyles}>
                        <div style={fillerStyles}>
                            <span style={labelStyles}>{`${staffObject.debt}`}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.debtor_order}>{staffObject.order}</div>
                <div className={styles.debtor_resilution}>
                    {/*<form action="">*/}
                    <input type="checkbox" className={styles.debtor_item_checkbox_custom}
                        // id='resilution_item_id_checkbox'
                    />
                    <label className={styles.debtor_item_label_custom}
                        // htmlFor='resilution_item_id_checkbox'
                    ></label>
                    {/*</form>*/}
                </div>
                <div className={styles.debtor_hidden_container_parent}>
                    <div ref={indexBlockRef}
                         onClick={handleIndexBlockClick}>
                        Whatsapp <ArrDown/>
                    </div>
                    {isSocialBlockVisible && (
                        <div ref={socialBlockRef} className={styles.debtor_hidden_container_child}>
                            <ul>
                                <li>
                                    <input type="checkbox" className={styles.debtor_item_checkbox_custom}
                                        // id='resilution_item_id_checkbox'
                                    />
                                    <label className={styles.debtor_item_label_custom}
                                        // htmlFor='resilution_item_id_checkbox'
                                    ><span>SMS</span></label>
                                </li>
                                <li>
                                    <input type="checkbox" className={styles.debtor_item_checkbox_custom}
                                        // id='resilution_item_id_checkbox'
                                    />
                                    <label className={styles.debtor_item_label_custom}
                                        // htmlFor='resilution_item_id_checkbox'
                                    ><span>E-mail</span></label>
                                </li>
                                <li>
                                    <input type="checkbox" className={styles.debtor_item_checkbox_custom}
                                        // id='resilution_item_id_checkbox'
                                    />
                                    <label className={styles.debtor_item_label_custom}
                                        // htmlFor='resilution_item_id_checkbox'
                                    ><span>Telegram</span></label>
                                </li>
                                <li><input type="checkbox" className={styles.debtor_item_checkbox_custom}
                                    // id='resilution_item_id_checkbox'
                                />
                                    <label className={styles.debtor_item_label_custom}
                                        // htmlFor='resilution_item_id_checkbox'
                                    ><span>Whatsapp</span></label>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
                <div>
                    {/*<form action="">*/}
                    <input type="checkbox" className={styles.debtor_item_checkbox_custom}
                           // id='debtor_item_id_checkbox'
                    />
                    <label className={styles.debtor_item_label_custom}
                           // htmlFor='debtor_item_id_checkbox'
                    ></label>
                    {/*</form>*/}
                </div>
                <div>
                    <a href='/'>
                        <MessageOne/>
                    </a>
                </div>


            </div>
            <div className={styles.list_debtors} id={'list_debtors_position'}
                 style={{
                     display: isDebtorsBlockList ? 'block' : 'none',
                 }}>
                <div className={styles.debtors_list_position}
                     // style={{
                     //     top: debtorsBlockList.top,
                     //     left: debtorsBlockList.left,
                     // }}
                >
                    <div>
                        <div>Задолженности</div>
                        <div onClick={() => setIsDebtorsBlockList(!isDebtorsBlockList)}><Close/></div>
                    </div>
                    <div>
                        <div className={styles.debtors_list_position_inside}>
                            <ul className={styles.list_debtors_title}>
                                <li>№ заказ</li>
                                <li>Дни задолженности</li>
                                <li>Сумма долга</li>
                            </ul>
                            <div>
                                {staffObject?.debts?.map((arrear, j) => (
                                    <ul key={j} className={styles.list_debtors_item}>
                                        <li>{arrear.numberOrder}</li>
                                        <li>{arrear.debtDays} </li>
                                        <li>{arrear.sumDebtor} руб.</li>
                                    </ul>
                                ))}
                            </div>
                            {/*</ul>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.list_debtors_hidden}  
                        ${isDebtorsBlockList ? styles.list_debtors_hidden_block : ''} `}>
            </div>
        </li>
    )

}

export default DebtorEmployeeItem