import React, {useState} from "react";
import {ReactComponent as ArrDown} from "../../assets/icons/arrDownInCircle.svg";



const SelectCustom = ({Main, DropDownContainer, DropDownHeader, DropDownListContainer, DropDownList, ListItem , options}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = value => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };


    return (
        <Main
            style={{
                border: isOpen ? '1px solid #04B0FB' : 'none',
                borderLeft: isOpen ? '1px solid #04B0FB' : 'none',
                borderRight: isOpen ? '1px solid #04B0FB' : 'none',
                borderBottom: isOpen ? '1px solid #04B0FB' : 'none',
                borderRadius: isOpen ? '4px 4px 0 0 ' : '0',
            }}
            // id={styles.select_block}
        >
            <DropDownContainer
                // id={styles.select_block_size}
            >
                <DropDownHeader onClick={toggling}
                // style={{backgroundImage:  isOpen? <ArrDown/> : ''}}
                >
                    {selectedOption || options[0]}

                     <ArrDown width={20}
                     style={{ transform: isOpen?  'rotate(180deg)' : ''}}
                     />
                </DropDownHeader>
                {isOpen && (
                    <DropDownListContainer>
                        <DropDownList>
                            {options.map(option => (
                                <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                                    {option}
                                </ListItem>
                            ))}
                        </DropDownList>
                    </DropDownListContainer>
                )}
            </DropDownContainer>
        </Main>
    );


}
export default SelectCustom