import React from 'react'
import styles from './../sideBar/sideBar.module.scss'

const CollapseItem = ({personalSubMenu, isThin}) => {
    return (
        <a href={personalSubMenu.path}>
            <li className={`${styles.personal_item} ${isThin ? styles.mini_personal : ''}`}
                // onClick={showPersonalBlock}
            >
                <div className={styles.menu_icon}>{personalSubMenu.icon} </div>
                <span className={styles.menu_title}>{personalSubMenu.title}</span>

            </li>
        </a>

    )
}

export default CollapseItem
