import styles from '../../styles/home.module.scss'
import React, {useState, useRef, useEffect} from "react";

const Search = ({handleButtonClick}) => {
    const [isSearchTerm, setIsSearchTerm] = useState("");
    const [isSearchHistory, setIsSearchHistory] = useState([]);
    const [isShowHistory, setIsShowHistory] = useState(false);
    const inputRef = useRef(null);
    const historyRef = useRef(null);

    const handleSearchChange = (event) => {
        setIsSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (isSearchTerm.trim() !== "") {
            setIsSearchHistory((prevHistory) => [isSearchTerm, ...prevHistory]);
            setIsSearchTerm("");
            //  логикa выполнения поиска
        }
    };


    const handleInputClick = () => {
        setIsShowHistory(true);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target) && historyRef.current && !historyRef.current.contains(event.target)) {
            setIsShowHistory(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={styles.search_block}>
            <form onSubmit={handleSearchSubmit}>
                <input
                    type="text"
                    value={isSearchTerm}
                    onChange={handleSearchChange}
                    placeholder={'Поиск'}
                    onClick={handleInputClick}
                    ref={inputRef}
                    style={{
                        borderTop: isShowHistory ? '2px solid #04B0FB' : 'none',
                        borderRight: isShowHistory ? '2px solid #04B0FB' : 'none',
                        borderLeft: isShowHistory ? '2px solid #04B0FB' : 'none',
                        borderBottom: isShowHistory ? 'block' : 'none',
                        outline: !isShowHistory ? '1.5px solid #999999' : 'none',
                        borderRadius: !isShowHistory ? ' 5px' : '8px 8px 0 0',
                    }}
                />
                <input type="submit" value=''
                       onClick={handleButtonClick}
                />
            </form>
            {isShowHistory && (
                <div className={styles.history_search}
                     ref={historyRef}
                     style={{
                         borderBottom: isShowHistory ? '2px solid #04B0FB' : 'none',
                         borderRight: isShowHistory ? '2px solid #04B0FB' : 'none',
                         borderLeft: isShowHistory ? '2px solid #04B0FB' : 'none',
                         borderTop: isShowHistory ? 'block' : 'none',
                         borderRadius: !isShowHistory ? ' 0' : ' 0 0 8px 8px',
                     }}>
                    <div className={styles.history_search_block}>
                        <div>История поиска</div>
                        <div onClick={() => setIsSearchHistory([])}> Очистить историю</div>
                    </div>

                    <ul>
                        {isSearchHistory.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>


    );
};

export default Search;
