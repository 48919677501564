import React from 'react'
import styles from './../sideBar/sideBar.module.scss'

import LeadItem from './LeadItem'

const LeadList = ({ leadSubMenu, isLeadOpen, isThin }) => {
    return (
        <ul style={{ display: isLeadOpen && isThin ? 'block': 'none' }} className={`${styles.personal_list} ${isThin ? styles.mini_personalList : ''}`}>
            {leadSubMenu.map((item, i) => (
                <LeadItem key={i} isThin={isThin} leadSubMenu={item} />
            ))}
        </ul>
    )
}

export default LeadList