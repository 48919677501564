import React from "react";
import {ReactComponent as EmptyStar} from "../../assets/icons/emptyStar.svg";
import {ReactComponent as NotEmptyStar} from "../../assets/icons/notEmptyStar.svg";

const StarRating = ({value}) => {
    const stars = Array(5).fill(<span> <EmptyStar/></span>);

    for (let i = 0; i < value; i++) {
        stars[i] = <span><NotEmptyStar/></span>;
    }
    return (
        <div>
            {stars}
        </div>
    )
}

export default StarRating