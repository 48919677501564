import * as React from "react";
import { Calendar as CalendarPicker, type Value } from "react-multi-date-picker"
import DateObject from "react-date-object";
import "./Calendar.scss"
import { getMonths, getWeekDays } from "./utils";

type Props = {
  value: Value
  onChange?(selectedDates: DateObject | DateObject[] | null): void
}
export function Calendar(props: Props) {
  const {
    onChange,
    value,
  } = props

  return (
    <CalendarPicker
      multiple
      onChange={onChange}
      value={value}
      className="calendar-multi-date-picker"
      weekStartDayIndex={1}
      monthYearSeparator=' '
      weekDays={getWeekDays()}
      months={getMonths()}
    />
  );
}

