import React from "react";
import styles from '../../styles/home.module.scss'
import {ReactComponent as GlobusBlue} from "../../assets/icons/globus_blue.svg";
import {ReactComponent as CloseBtn} from "../../assets/icons/close.svg";
import OrderItem from "./OrderItem";

const OrderList = ({setIsOrdListOpen, orderSubList, isOrdListOpen}) => {

    return (
        <ul style={{display: isOrdListOpen ? 'block' : 'none'}} className={styles.order_list}>
            <div className={styles.order_list_visible}>
                <div className={styles.order_list_pos}>
                    <div className={styles.order_list_ttl}>
                        <GlobusBlue/> Список интернет заказаов
                    </div>
                    <div className={styles.order_close_btn} onClick={() => setIsOrdListOpen(false)}>
                        <CloseBtn/>
                    </div>
                </div>

                {orderSubList.map((item, i) => (
                    <OrderItem key={i} orderSubList={item}/>
                ))}
            </div>

        </ul>
    )
}
export default OrderList