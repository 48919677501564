import React from "react";
import styles from './../sideBar/sideBar.module.scss'

const LeadItem = ({leadSubMenu, isThin}) => {
    return (
        <li className={`${styles.personal_item} ${isThin ? styles.mini_personal : ''}`}>
            <div className={styles.menu_icon}>{leadSubMenu.icon} </div>
            <span className={styles.menu_title}>{leadSubMenu.title}</span>
        </li>
    )

}

export default LeadItem