import React, {useRef, useEffect} from 'react'
import {useUser} from '../../hooks'
import {ReactComponent as Finance} from './../../assets/icons/finance.svg'
import {ReactComponent as University} from './../../assets/icons/university.svg'
import {ReactComponent as ArrowMain} from './../../assets/icons/arrow_main.svg'
import {ReactComponent as BlackList} from './../../assets/icons/black_list.svg'
import logo_mini from './../../assets/icons/logo_mini.svg'
import {ReactComponent as Analytics} from './../../assets/icons/analytics.svg'
import {ReactComponent as Bills} from './../../assets/icons/bills.svg'
import {ReactComponent as Calendar} from './../../assets/icons/calendar.svg'
import {ReactComponent as Сlients} from './../../assets/icons/clients.svg'
import {ReactComponent as Leads} from './../../assets/icons/leads.svg'
import {ReactComponent as Archive} from './../../assets/icons/archive.svg'
import {ReactComponent as Invoice} from './../../assets/icons/invoice.svg'
import {ReactComponent as Counterparties} from './../../assets/icons/counterparties.svg'
import {ReactComponent as Main} from './../../assets/icons/main.svg'
import {ReactComponent as Orders} from './../../assets/icons/orders.svg'
import {ReactComponent as Personal} from './../../assets/icons/personal.svg'
import {ReactComponent as Service} from './../../assets/icons/service.svg'
import {ReactComponent as Settings} from './../../assets/icons/settings.svg'
import {ReactComponent as ArrowUp} from './../../assets/icons/arrow_up.svg'
import {ReactComponent as Schedule} from './../../assets/icons/schedule.svg'
import {ReactComponent as Cleaners} from './../../assets/icons/cleaners.svg'
import {ReactComponent as Masters} from './../../assets/icons/masters.svg'
import {ReactComponent as Managers} from './../../assets/icons/managers.svg'
import styles from './sideBar.module.scss'
import CollapseList from '../CollapseList/CollapseList'
import LeadList from '../LeadList/LeadList'
import DocumentsList from '../DocumentsList/DocumentsList'


const SideBar = () => {
    // const isBurgerOpen = useSelector(burgerMenuSelector)

    const [isThin, setIsThin] = React.useState(false)

    const [isPersonalOpen, setPersonalOpen] = React.useState(false)
    const firstPersonalBlockRef = useRef(null);
    const secondPersonalBlockRef = useRef(null);

    useEffect(() => {
        const handlePersonalOutsideClick = (event) => {
            if (
                secondPersonalBlockRef.current &&
                !secondPersonalBlockRef.current.contains(event.target) &&
                firstPersonalBlockRef.current &&
                !firstPersonalBlockRef.current.contains(event.target)
            ) {
                setPersonalOpen(false);
            }
        };

        document.addEventListener('mousedown', handlePersonalOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handlePersonalOutsideClick);
        };
    }, []);

    const [isLeadOpen, setIsLeadOpen] = React.useState(false)
    const firstLeadBlockRef = useRef(null);
    const secondLeadBlockRef = useRef(null);

    useEffect(() => {
        const handleLeadOutsideClick = (event) => {
            if (
                secondLeadBlockRef.current &&
                !secondLeadBlockRef.current.contains(event.target) &&
                firstLeadBlockRef.current &&
                !firstLeadBlockRef.current.contains(event.target)
            ) {
                setIsLeadOpen(false);
            }
        };

        document.addEventListener('mousedown', handleLeadOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleLeadOutsideClick);
        };
    }, []);

    const [isDocumentsOpen, setIsDocumentsOpen] = React.useState(false)
    const firstDocumentsBlockRef = useRef(null);
    const secondDocumentsBlockRef = useRef(null);

    useEffect(() => {
        const handleDocumentsOutsideClick = (event) => {
            if (
                secondDocumentsBlockRef.current &&
                !secondDocumentsBlockRef.current.contains(event.target) &&
                firstDocumentsBlockRef.current &&
                !firstDocumentsBlockRef.current.contains(event.target)
            ) {
                setIsDocumentsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentsOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentsOutsideClick);
        };
    }, []);
    // const profile = useSelector(profileSelector)
    // const history = useHistory()
    const personalSubMenu = [
        {title: 'Персонал', icon: <Personal/>, path: '/staffPage'},
        {title: 'Клинеры', icon: <Cleaners/>, path: '/cleaners'},
        {title: 'Карточка клинера', icon: <Cleaners/>, path: '/cleanerCard'},
        {title: 'Создать карточку клинера', icon: <Cleaners/>, path: '/staffForm'},
        {title: 'Мастера химчистки', icon: <Masters/>, path: '/'},
        {title: 'Менеджеры', icon: <Managers/>, path: '/'},
        {title: 'График работы', icon: <Schedule/>, path: '/cleanerCard'},
    ]

    const leadSubMenu = [
        {title: 'Архив', icon: <Archive/>},
        {title: 'Лиды', icon: <Leads/>},
    ]
    const documentsSubMenu = [
        {title: 'Архив', icon: <Archive/>},
        {title: 'Выставить счет', icon: <Invoice/>},
        {title: 'Контрагенты', icon: <Counterparties/>},
    ]

    const hide = `${isThin ? styles.hide : ''}`
    const mini_item = `${styles.menu_item} ${isThin ? styles.mini_item : ''}`
    const personal_list_btn = `${isPersonalOpen ? styles.personal_list_btn : ''}`
    const lead_list_btn = `${isLeadOpen ? styles.personal_list_btn : ''}`
    const documents_list_btn = `${isDocumentsOpen ? styles.personal_list_btn : ''}`
    // const handleLogout = () => {
    //   localStorage.removeItem('token')
    //   window.location.reload()
    // }


    const dropdownRef = useRef();

    const handleClickInside = () => {
        setIsThin(true);
        document.addEventListener('mousedown', handleClickOutside);
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setIsThin(false);
            document.removeEventListener('mousedown', handleClickOutside);
        }
    };

    return (
        <div ref={dropdownRef}>
            <div className={`${styles.wrapper}  ${isThin ? styles.mini_wrapper : ''} `} onClick={handleClickInside}
                 style={{display: !isThin ? 'block' : 'none'}}
            ><img className={`${styles.logo} ${isThin ? styles.mini_logo : ''}`} src={logo_mini}/>
                <div className={`${styles.division_line} ${hide}`}></div>
                {/*<img src={isOpen ? 'open-image.jpg' : 'closed-image.jpg'} alt="Dropdown trigger" />*/}
                <ul className={styles.menu}>
                    <li className={mini_item}>
                        <Main className={`${styles.menu_icon} ${styles.menu_first_svg}`}/>
                    </li>
                    <li className={mini_item}>
                        <Orders className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Personal className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Сlients className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Service className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Leads className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Analytics className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Calendar className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Bills className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Settings className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <BlackList className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <Finance className={`${styles.menu_icon} `}/>
                    </li>
                    <li className={mini_item}>
                        <University className={`${styles.menu_icon} `}/>
                    </li>
                </ul>
            </div>
            {isThin && (
                <div style={{display: 'flex'}}>
                    <div className={`${styles.container} ${isThin ? styles.mini_container : ''}`}>
                        <img className={`${styles.logo} ${isThin ? styles.mini_logo : ''}`} src={logo_mini}/>
                        <div className={`${styles.division_line} ${hide}`}></div>


                        <ul className={styles.menu}>
                            <a href={'/'}>
                                <li className={`${hide} ${mini_item}`}>
                                    <Main className={`${styles.menu_icon} ${styles.menu_first_svg}`}/>
                                    <span className={styles.menu_title}>Главная</span>
                                    <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up}`}/>
                                </li>
                            </a>
                            <li className={mini_item}>
                                <Orders className={`${styles.menu_icon} `}/>
                                <div className={styles.menu_title}>Заказы</div>
                            </li>
                            <li className={`${mini_item} ${personal_list_btn}`}
                                ref={firstPersonalBlockRef}
                                onClick={() => setPersonalOpen(!isPersonalOpen)}>
                                <Personal className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Персонал</span>
                                <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up2}`}
                                           style={{display: isThin && isPersonalOpen ? "block" : "none"}}/>
                                <ArrowUp style={{
                                    transform: isThin ? "rotate(180deg)" : "none",
                                    display: isThin && !isPersonalOpen ? "block" : "none",
                                }} className={styles.arrow_up}/>
                            </li>
                            {isPersonalOpen && (
                                <div ref={secondPersonalBlockRef}>
                                    <CollapseList isThin={isThin} isPersonalOpen={isPersonalOpen}
                                                  personalSubMenu={personalSubMenu}/>
                                </div>

                            )}


                            <li className={mini_item}>
                                <Сlients className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Клиенты</span>
                            </li>
                            <li className={mini_item}>
                                <Service className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Сервис</span>
                            </li>

                            <li className={`${mini_item} ${lead_list_btn}`}
                                ref={firstLeadBlockRef}
                                onClick={() => setIsLeadOpen(!isLeadOpen)}>
                                <Leads className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Лиды</span>
                                <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up3}`}
                                           style={{display: isThin && isLeadOpen ? "block" : "none"}}/>
                                <ArrowUp style={{
                                    transform: isThin ? "rotate(180deg)" : "none",
                                    display: isThin && !isLeadOpen ? "block" : "none",
                                }} className={styles.arrow_up2}/>
                            </li>

                            {isLeadOpen && (
                                <div ref={secondLeadBlockRef}>
                                    <LeadList isThin={isThin} isLeadOpen={isLeadOpen}
                                              leadSubMenu={leadSubMenu}/>
                                </div>
                            )}

                            <li className={mini_item}>
                                <Analytics className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Отчеты</span>
                            </li>
                            <li className={mini_item}>
                                <Calendar className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Календарь</span>
                            </li>
                            <li className={`${mini_item} ${documents_list_btn}`}
                                ref={firstDocumentsBlockRef}
                                onClick={() => setIsDocumentsOpen(!isDocumentsOpen)}>
                                <Bills className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Документы</span>
                                <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up4}`}
                                           style={{display: isThin && isDocumentsOpen ? "block" : "none"}}/>
                                <ArrowUp style={{
                                    transform: isThin ? "rotate(180deg)" : "none",
                                    display: isThin && !isDocumentsOpen ? "block" : "none",
                                }} className={styles.arrow_up3}/>
                            </li>

                            {isDocumentsOpen && (
                                <div ref={secondDocumentsBlockRef}>
                                    <DocumentsList isThin={isThin} isDocumentsOpen={isDocumentsOpen}
                                                   documentsSubMenu={documentsSubMenu}/>
                                </div>
                            )}

                            <li className={mini_item}>
                                <Settings className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Настройки</span>
                            </li>
                            <li className={mini_item}>
                                <BlackList className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Черный список</span>
                            </li>
                            <li className={mini_item}>
                                <Finance className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>Финансы</span>
                            </li>
                            <li className={mini_item}>
                                <University className={`${styles.menu_icon} `}/>
                                <span className={styles.menu_title}>База знаний</span>
                            </li>
                        </ul>

                    </div>
                    <div className={`${styles.wrapper}  ${isThin ? styles.mini_wrapper : ''} `}
                         onClick={() => setIsThin(false)}></div>
                </div>
            )}
        </div>





        // <div className={styles.sidebar_wrapper} ref={dropdownRef}>
        //     <div
        //         className={`${styles.wrapper}  ${isThin ? styles.mini_wrapper : ''} `}
        //         onClick={handleClickInside}>
        //         {isThin && (
        //             <div className={`${styles.container} ${isThin ? styles.mini_container : ''}`}>
        //                 <img className={`${styles.logo} ${isThin ? styles.mini_logo : ''}`} src={logo_mini}/>
        //                 <div className={`${styles.division_line} ${hide}`}></div>
        //
        //
        //                     <ul  className={styles.menu}   >
        //                         <li className={`${hide} ${mini_item}`}>
        //                             <Main className={`${styles.menu_icon} ${styles.menu_first_svg}`}/>
        //                             <span className={styles.menu_title}>Главная</span>
        //                             <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up}`}/>
        //                         </li>
        //                         <li className={mini_item}>
        //                             <Orders className={`${styles.menu_icon} `}/>
        //                             <div className={styles.menu_title}>Заказы</div>
        //                         </li>
        //                         <li className={`${mini_item} ${personal_list_btn}`}
        //                             onClick={() => setPersonalOpen(!isPersonalOpen)}>
        //                             <Personal className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Персонал</span>
        //                             <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up2}`}
        //                                        style={{display: isThin && isPersonalOpen ? "block" : "none"}}/>
        //                             <ArrowUp style={{
        //                                 transform: isThin ? "rotate(180deg)" : "none",
        //                                 display: isThin && !isPersonalOpen ? "block" : "none",
        //                             }} className={styles.arrow_up}/>
        //                         </li>
        //                         <CollapseList isThin={isThin} isPersonalOpen={isPersonalOpen}
        //                                       personalSubMenu={personalSubMenu}/>
        //                         <li className={mini_item}>
        //                             <Сlients className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Клиенты</span>
        //                         </li>
        //                         <li className={mini_item}>
        //                             <Service className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Сервис</span>
        //                         </li>
        //
        //                         <li className={`${mini_item} ${lead_list_btn}`}
        //                             onClick={() => setIsLeadOpen(!isLeadOpen)}>
        //                             <Leads className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Лиды</span>
        //                             <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up3}`}
        //                                        style={{display: isThin && isLeadOpen ? "block" : "none"}}/>
        //                             <ArrowUp style={{
        //                                 transform: isThin ? "rotate(180deg)" : "none",
        //                                 display: isThin && !isLeadOpen ? "block" : "none",
        //                             }} className={styles.arrow_up2}/>
        //                         </li>
        //                         <LeadList isThin={isThin} isLeadOpen={isLeadOpen}
        //                                   leadSubMenu={leadSubMenu}/>
        //
        //                         <li className={mini_item}>
        //                             <Analytics className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Аналитика</span>
        //                         </li>
        //                         <li className={mini_item}>
        //                             <Calendar className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Календарь</span>
        //                         </li>
        //                         <li className={`${mini_item} ${documents_list_btn}`}
        //                             onClick={() => setIsDocumentsOpen(!isDocumentsOpen)}>
        //                             <Bills className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Документы</span>
        //                             <ArrowMain className={`${styles.menu_title} ${styles.big_arrow_up4}`}
        //                                        style={{display: isThin && isDocumentsOpen ? "block" : "none"}}/>
        //                             <ArrowUp style={{
        //                                 transform: isThin ? "rotate(180deg)" : "none",
        //                                 display: isThin && !isDocumentsOpen ? "block" : "none",
        //                             }} className={styles.arrow_up3}/>
        //                         </li>
        //                         <DocumentsList isThin={isThin} isDocumentsOpen={isDocumentsOpen}
        //                                        documentsSubMenu={documentsSubMenu}/>
        //                         <li className={mini_item}>
        //                             <Settings className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Настройки</span>
        //                         </li>
        //                         <li className={mini_item}>
        //                             <BlackList className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Черный список</span>
        //                         </li>
        //                         <li className={mini_item}>
        //                             <University className={`${styles.menu_icon} `}/>
        //                             <span className={styles.menu_title}>Черный список</span>
        //                         </li>
        //                     </ul>
        //
        //             </div>
        //         )}
        //     </div>
        // </div>
    )
}

export default SideBar


// <div className={`${styles.user} ${isThin ? styles.mini_user : ''}`}>
// <img src={user_avatar} className={`${styles.avatar} ${isThin ? styles.mini_avatar : ''}`} />
// <div className={`${styles.user_info} ${hide}`}>
//     <span className={`${styles.user_name} ${hide}`}>{profile.name}</span>
//     <span className={`${styles.user_role} ${hide}`}>Менеджер</span>
// </div>
// <img className={hide} src={arrows} />
// </div>
// <div  >

// <div className={styles.collapse } onClick={() => setIsThin(!isThin)}>
// <span className={`${styles.collapse_title} ${hide}`}>Свернуть</span>
// <img src={collapse_btn} style={{ transform: `rotate(${isThin ? 180 : 0}deg)` }} className={collapse_btn} />
// </div>